import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import { Build as BuildIcon, Close as CloseIcon } from '@material-ui/icons';
import { format as formatDate } from 'date-fns';
import { Fullscreen as FullscreenIcon } from '@material-ui/icons';

import * as colors from '../colors';
import MaterialTable from '../components/MaterialTableWrapper';

const GET_KEYCLOAKUSERS = gql`
	query ($filter: KeycloakUserFilter!) {
		getKeycloakUsers(filter: $filter) {
			id
			firstName
			lastName
		}
	}
`;

const NO_REDIRECT_ID = 'no-redirect';

/**
 * Table meant for displaying a list of uploaded documents, changing documents names, and deleting documents
 * @param {Array of Objects} documents : Listed in graph
 * @param {Function} onRename : Called when a document is to be renamed, parameters: (document: Object, newName: String)
 * @param {Function} onDelete : Called when a document is to be deleted, parameters: (document: Object)
 */
function DocumentList(props) {
	const [documentUnderEdit, setDocumentUnderEdit] = useState();
	const [newDocumentName, setNewDocumentName] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [openDocument, setOpenDocument] = useState(null);

	const { t } = useTranslation();

	const keycloakUsers =
		useQuery(GET_KEYCLOAKUSERS, {
			skip: !props.documents?.length,
			variables: { filter: { ids: [...new Set((props.documents || []).map(dat => dat.uploaderid))] } },
		}).data?.getKeycloakUsers || [];

	/* function openDocument(doc) {
		window.open(process.env.REACT_APP_FILES_SERVER_URL + doc.locationid + '/' + doc.name);
	} */

	function editDocument(doc) {
		setDocumentUnderEdit(doc);
		setNewDocumentName(doc ? doc.displayname : '');
		setConfirmDelete(false);
	}

	function renameDocument(doc, newName) {
		if (newName && doc.displayname !== newName) props.onRename(doc, newName);
		editDocument();
	}

	function deleteDocument(doc) {
		if (doc && confirmDelete) {
			props.onDelete(doc);
			editDocument();
		} else if (documentUnderEdit) {
			setConfirmDelete(true);
		}
	}

	function handleOpenDocument(doc) {
		setOpenDocument(doc);
	}

	function handleCloseDocument() {
		setOpenDocument(null);
	}

	function openFullScreen(url) {
		const newWindow = window.open(url, '_self');
		// use handle close document to close the dialog
		// handleCloseDocument();
		if (newWindow) {
			newWindow.document.title = 'Full Screen';
			newWindow.document.body.style.margin = '0';
		}
	}

	return (
		<div>
			<MaterialTable
				title={t('documentList.document')}
				columns={[
					{ title: t('generic.name'), field: 'displayname' },
					{
						title: t('documentList.added'),
						field: 'dateuploaded',
						customSort: (a, b) => {
							return new Date(a.date) < new Date(b.date) ? -1 : 1;
						},
					},
					{
						title: t('documentList.by'),
						field: 'uploaderid',
						render: row => (
							<div style={{ display: 'flex' }}>
								{(() => {
									const user = keycloakUsers.find(use => use.id === row.uploaderid) || {};
									return (user.firstName || '') + ' ' + (user.lastName || '');
								})()}

								{/* Not related to uploader, should be inside the rightmost column for spacing */}
								<IconButton onClick={() => editDocument(row)} style={{ padding: '0.4rem', margin: 'auto 0 auto auto' }}>
									<BuildIcon id={NO_REDIRECT_ID} style={{ width: '1rem', height: '1rem' }} />
								</IconButton>
							</div>
						),
					},
				]}
				data={props.documents.map(doc => {
					return { ...doc, dateuploaded: formatDate(new Date(doc.dateuploaded), 'd.M.yyyy') };
				})}
				options={{
					pageSize: props.documents ? props.documents.length : 0,
					pageSizeOptions: [],
					headerStyle: {
						backgroundColor: colors.primary0,
						color: '#fff',
						height: '2.4rem',
						fontWeight: '430',
						fontSize: '98%',
					},
					rowStyle: {
						color: '#444',
						height: '2rem',
						whiteSpace: 'nowrap',
					},
				}}
				components={{
					Pagination: () => null,
				}}
				/* 	onRowClick={(event, rowData) => {
						const noRedirectFlag = event._dispatchInstances.some(dispatch => dispatch.child?.stateNode?.children?.[NO_REDIRECT_ID]);
						if (!noRedirectFlag) openDocument(rowData);
					}} */
				onRowClick={(event, rowData) => {
					const noRedirectFlag = event._dispatchInstances.some(
						(dispatch) => dispatch.child?.stateNode?.children?.[NO_REDIRECT_ID]
					);
					if (!noRedirectFlag) handleOpenDocument(rowData);
				}}
				style={{ width: '57.9rem', margin: '0 auto', overflow: 'hidden' }}
			/>
			{openDocument && (
				<Dialog open={!!openDocument} /* onClose={handleCloseDocument} */ fullWidth maxWidth="md">
					{/* <DialogTitle>{t('documentList.document')} </DialogTitle> */}
					<DialogContent>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button onClick={() => openFullScreen(`${process.env.REACT_APP_FILES_SERVER_URL}${openDocument.locationid}/${openDocument.name}`)} color="primary">
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<FullscreenIcon />
									<span style={{ marginLeft: '10px' }}>{t('generic.openFullscreen')}</span>
								</div>
							</Button>
						</div>
						<iframe
							title={openDocument.displayname}
							src={`${process.env.REACT_APP_FILES_SERVER_URL}${openDocument.locationid}/${openDocument.name}`}
							style={{ width: '100%', height: '72vh', border: 'none' }}
						></iframe>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDocument} style={{ color: 'white', backgroundColor: 'red' }}>
							{t('generic.closeDocument')}
							{/*  <CloseIcon /> */}
						</Button>

					</DialogActions>
				</Dialog>
			)}

			{/* Popup dialog for editing or removing document */}
			<Dialog open={Boolean(documentUnderEdit)} onClose={() => editDocument()}>
				{/* Close/X button */}
				<IconButton
					onClick={() => editDocument()}
					style={{ position: 'absolute', right: '0', margin: '0.3rem', padding: '0.4rem' }}
				>
					<CloseIcon style={{ width: '1.15rem', height: '1.15rem' }} />
				</IconButton>

				<DialogTitle>{t('documentList.editDocument')}</DialogTitle>

				<DialogContent style={{ paddingTop: '0' }}>
					<TextField label='Namn' value={newDocumentName} onChange={event => setNewDocumentName(event.target.value)} fullWidth />
				</DialogContent>

				<DialogActions style={{ padding: '1rem' }}>
					<Button
						onClick={() => renameDocument(documentUnderEdit, newDocumentName)}
						disabled={confirmDelete}
						style={{ textTransform: 'none', color: colors.text}}
						autoFocus
					>
						{t('documentList.saveChanges')}
					</Button>
					<Button disabled={confirmDelete} onClick={() => deleteDocument()} color='secondary' style={{ textTransform: 'none' }}>
						{t('documentList.deleteDocument')}
					</Button>
				</DialogActions>

				{confirmDelete && (
					<Button
						onClick={() => deleteDocument(documentUnderEdit)}
						color='secondary'
						style={{
							color: '#fff',
							background: '#f00',
							borderRadius: '0',
							height: '2.7rem',
							fontSize: '110%',
							textTransform: 'none',
						}}
					>
						{t('documentList.confirmRemoval')}
					</Button>
				)}
			</Dialog>
		</div>
	);
}

export default DocumentList;

import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Dialog, Backdrop, LinearProgress } from '@material-ui/core';
import { ArrowDropDown as ArrowDownIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { addDays, format as formatDate } from 'date-fns';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { PAGES, INTERVAL_VALUES, CENTER_CONTENT_STYLE, UNIT_CLASSIFICATIONS, CLASSIFICATIONS } from '../constants';
import * as colors from '../colors';
import { getEnergySensors, getFormattedNumber } from '../utility-functions';
import { CustomDivider } from '../components/CustomDivider';
import { OverviewChart } from '../components/OverviewChart';
import { setPage } from '../redux/actionCreators';
import { getStateVariables, STORE } from '../redux/selectors';
import { OverviewMiniInfo } from '../components/OverviewMiniInfo';
import MostAlarmsChart from '../components/MostAlarmsChart';
import TrendsChart from '../components/TrendsChart';
import EnergySensorSelector from '../components/EnergySensorSelector';
import i18n from '../i18n';

const REVERSE_DAYS = 0; // How many days should data-fetching reverse (to account for data arriving late)

const GET_MULTI_DATA = gql`
	query GetOverviewMultiData($weeklyViewFilter: WeeklyViewFilter!,$alarmFilter: AlarmFilter!, $confirmFilter: ConfirmFilter!) {
		getWeekSensordataView(filter: $weeklyViewFilter) {
			locationid
			sensorid
			timestamp
			value
		}
		getSimpleAlarmView(filter: $alarmFilter) {
			sensorid
			locationid
			latesttimestamp
		}
		getConfirms(filter: $confirmFilter) {
			sensorid
			timestamp
		}
	}
`;
const GET_DAY_MONTH_YEAR_DATA = gql`
	query GetOverviewDayMonthYearData($dayViewFilter: DayViewFilter!, $monthlyViewFilter: MonthlyViewFilter!, $yearlyViewFilter: YearlyViewFilter!) {
		getDaySensordataView(filter: $dayViewFilter) {
			locationid
			sensorid
			timestamp
			value
		}
		getMonthSensordataView(filter: $monthlyViewFilter) {
			locationid
			sensorid
			timestamp
			value
		}
		getYearSensordataView(filter: $yearlyViewFilter) {
			locationid
			sensorid
			timestamp
			value
		}
}
`;
const GET_DAY_DATA = gql`
    query GetDayData($dayViewFilter: DayViewFilter!) {
        getDaySensordataView(filter: $dayViewFilter) {
            locationid
            sensorid
            timestamp
            value
        }
    }
`;
const GET_MONTH_DATA = gql`
    query GetMonthData($monthlyViewFilter: MonthlyViewFilter!) {
        getMonthSensordataView(filter: $monthlyViewFilter) {
            locationid
            sensorid
            timestamp
            value
        }
    }
`;
const GET_YEAR_DATA = gql`
    query GetYearData($yearlyViewFilter: YearlyViewFilter!) {
        getYearSensordataView(filter: $yearlyViewFilter) {
            locationid
            sensorid
            timestamp
            value
        }
    }
`;
const GET_PROPERTIES = gql`
	query {
		getSensorLocations {
			locationid
			street
		}
	}
`;
const GET_SENSOR_VIEW = gql`
query ($filter: EnergySensorsViewFilter) {
	getEnergySensorsView(filter: $filter) {
		sensorid
		sensorref
		name
		unit
		classification
		subcategory
		minvalue
		maxvalue
		lowerthreshold
		upperthreshold
		periodicity
		locationid
		city
		street
		cadastral
		area
		longitude
		latitude
		value
		timestamp
		sensorgroupid
		groupname
		multiplier
		includeintotal
		accumulateddata
		alarmgraceminutes
		digitaltwintagid
		digitaltwinperspectiveid
		setpoint
	}
}
`;


/* const ECPALARM_PREFIX = 'ECPALARM-';
const AlarmType = {
	Alarm: 1,
	Confirm: 2,
	EcpAlarm: 3,
	EcpConfirm: 4,
}; */

function getDataWithPrice(data, classificationId, subcategoryId, energyPrices, selectedInterval) {
	let priceMultip = 1;
	if (classificationId === UNIT_CLASSIFICATIONS.electricity.id || subcategoryId === CLASSIFICATIONS.energy.subcategories.electricity_usage.id || subcategoryId === UNIT_CLASSIFICATIONS.electricity.id)
		priceMultip = energyPrices[UNIT_CLASSIFICATIONS.electricity.energyIndex] || UNIT_CLASSIFICATIONS.electricity.priceMultiplier;
	else if (classificationId === UNIT_CLASSIFICATIONS.districtHeating.id || subcategoryId === CLASSIFICATIONS.energy.subcategories.district_heating_usage.id || subcategoryId === UNIT_CLASSIFICATIONS.districtHeating.id)
		priceMultip =
			energyPrices[UNIT_CLASSIFICATIONS.districtHeating.energyIndex] || UNIT_CLASSIFICATIONS.districtHeating.priceMultiplier;

	return data.map(datum => {
		return {
			date: new Date(datum.x),
			// Multiply by intervalHours to ensure that price changes according to the selected timeframe
			priceVal: datum.y ? datum.y * priceMultip : datum.y, // Don't multiply when y is null as it becomes 0
			// * selectedInterval.intervalHours.barChart
			unitVal: datum.y // * selectedInterval.intervalHours.barChart,
		};
	});
}

function getTotalCostForClassification(sensors, classificationId) {
	// if classification or subcategory is in classificationId list, then add the cost of the sensor
	const classSensors = sensors.filter(sen => {
		return (
			sen.includeintotal !== false &&
			(
				sen.classification === classificationId ||
				sen.subcategory === classificationId ||
				(sen.classification === CLASSIFICATIONS.energy.subcategories.electricity_usage.id &&
					classificationId === UNIT_CLASSIFICATIONS.electricity.id) ||
				(sen.classification === CLASSIFICATIONS.energy.subcategories.district_heating_usage.id &&
					classificationId === UNIT_CLASSIFICATIONS.districtHeating.id)
			)
		);
	});
	classSensors.forEach(sensor => {
		if (sensor.unit) {
			const unit = sensor.unit.toLowerCase();

			// Ensure the data is not transformed multiple times
			sensor.data = sensor.data.map(datum => {
				datum.isTransformed = datum.isTransformed || {};
				if (!unit.includes('kw') && unit.includes('m') && !datum.isTransformed.kmConversion) {
					datum.priceVal *= 1000;
					datum.unitVal *= 1000;
					datum.isTransformed.kmConversion = true;
				}

				if ((!unit.includes('h')) && !datum.isTransformed.avgConversion) {
					datum.priceVal /= (6 * 24);
					datum.unitVal /= (6 * 24);
					datum.isTransformed.avgConversion = true;
				}

				if (!unit.includes('k') && !unit.includes('m') && !datum.isTransformed.scaleDownConversion) {
					datum.priceVal /= 1000;
					datum.unitVal /= 1000;
					datum.isTransformed.scaleDownConversion = true;
				}

				// Clamp values to ensure no negative values
				datum.priceVal = Math.max(0, datum.priceVal);
				datum.unitVal = Math.max(0, datum.unitVal);

				return datum;
			});
		}
	});

	return classSensors.length
		? classSensors.reduce((totalCost, sen) => totalCost + sen.data.reduce((sensorCost, datum) => sensorCost + datum.unitVal, 0), 0)
		: 0;
}

function drawEnergyCost(cost, energyTypeString, energyTypeUnit) {
	return (
		<div style={{ display: 'flex' }}>
			<h2 style={{ margin: '0', fontWeight: '400', margin: '0.5rem 0 0.5rem 0', width: '2.8rem' }}>{getFormattedNumber(cost)} </h2>
			<h3 style={{ fontWeight: '400', margin: '0.7rem 0 0.7rem 2.8rem', whiteSpace: 'nowrap' }}>
				{energyTypeUnit} {i18n.t('overview.of_lowercase')} {energyTypeString}.
			</h3>
		</div>
	);
}

function getSelectionLabel(energySensors, selection) {
	if (!selection.length || energySensors.length === selection.length) return i18n.t('overview.allProps_lowercase');

	// City-based selection
	const selectedCities = [...new Set(selection.map(sel => sel.city))];
	const allCitySensAreSelected = selectedCities.every(
		cit => energySensors.filter(sen => sen.city === cit).length === selection.filter(sel => sel.city === cit).length
	);
	if (allCitySensAreSelected) return selectedCities.length === 1 ? selectedCities[0] : i18n.t('overview.selectedCities_lowercase');

	// Property-based selection
	const selectedProps = [...new Set(selection.map(sel => sel.locationid))];
	const allPropSensAreSelected = selectedProps.every(
		id => energySensors.filter(sen => sen.locationid === id).length === selection.filter(sel => sel.locationid === id).length
	);
	if (allPropSensAreSelected)
		return selectedProps.length === 1
			? energySensors.find(sen => sen.locationid === selectedProps[0])?.street || '-'
			: i18n.t('overview.selectedProps_lowercase');

	// Sensor-based selection
	const selectedSens = selection.map(sel => sel.sensorid);
	return selectedSens.length === 1
		? energySensors.find(sen => sen.sensorid === selectedSens[0])?.name || '-'
		: i18n.t('overview.selectedSens_lowercase');
}

// Returns all sensors which belong to a selection, not just the energy-sensors
function getAllSelectedSensorIds(allSensors, energySensors, selection) {
	const cities = [...new Set(selection.map(sel => sel.city))];
	const fullySelectedCities = cities.filter(
		cit => selection.filter(sel => sel.city === cit).length === energySensors.filter(sen => sen.city === cit).length
	);

	const propertyIds = [...new Set(selection.map(sel => sel.locationid))];
	const fullySelectedPropertyIds = propertyIds.filter(
		id => selection.filter(sel => sel.locationid === id).length === energySensors.filter(sen => sen.locationid === id).length
	);

	const selectionSensorIds = selection.map(sel => sel.sensorid);
	return allSensors
		.filter(
			sen =>
				fullySelectedCities.includes(sen.city) ||
				fullySelectedPropertyIds.includes(sen.locationid) ||
				selectionSensorIds.includes(sen.sensorid)
		)
		.map(sel => sel.sensorid);
}

/**
 * Displays accumulated energy consumption for specific region or property,
 * as well as estimated costs, property size summary, most alarms, and negatively and positively trending properties.
 */
function Overview(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [selectedInterval, setSelectedInterval] = useState(INTERVAL_VALUES.week);
	const [energySensors, setEnergySensors] = useState([]);
	const [alarms, setAlarms] = useState([]);
	const [confirms, setConfirms] = useState([]);
	const [showSelector, setShowSelector] = useState(false);
	const [selection, setSelection] = useState([]); // {sensorid, locationid, city}[]
	const { t } = useTranslation();
	const allSelectedSensorIds = getAllSelectedSensorIds(props.sensors, energySensors, selection);
	const selectedEnergySensors = (energySensors || []).filter(sen =>
		selection.map(sel => sel.sensorid).includes(sen.sensorid)
	);
	const selectedAlarms = (alarms || []).filter(ala =>
		selection.length === (energySensors || []).length || allSelectedSensorIds.includes(ala.sensorid)
	);
	const selectedConfirms = (confirms || []).filter(con =>
		selection.length === (energySensors || []).length || allSelectedSensorIds.includes(String(con.sensorid))
	);
	const energyConsumptionCosts = {
		electricity: getTotalCostForClassification(selectedEnergySensors, ((UNIT_CLASSIFICATIONS.electricity.id, CLASSIFICATIONS.energy.subcategories.electricity_usage.id))),
		districtHeating: getTotalCostForClassification(selectedEnergySensors, ((UNIT_CLASSIFICATIONS.districtHeating.id, CLASSIFICATIONS.energy.subcategories.district_heating_usage.id))),
	};
	const totalEnergyCost = Object.values(energyConsumptionCosts).reduce((sum, curr) => sum + curr);
	//const propertiesQuery = useQuery(GET_PROPERTIES);
	const sensorsQuery = useQuery(GET_SENSOR_VIEW, {
		variables: {
			energySensorsViewFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
		},
	});
	const multiDataQuery = useQuery(GET_MULTI_DATA, {
		variables: {
			weeklyViewFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
			alarmFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
			confirmFilter: {
				sensorids: props.sensors.map(sen => Number(sen.sensorid)),
				//locationids: props.properties.map(pro => Number(pro.locationid)), //TODO: write a query in backend to get locationids
			},
		},
		onCompleted: () => setIsLoading(false),

		// onCompleted: Doesn't work because when switching back to Overview page (after having used it before) it will try to load the same data as before and onCompleted is only called when the returned data is different from that returned last time
	});

	/* const dataQuery = useQuery(GET_DAY_MONTH_YEAR_DATA, {
		variables: {
			dayViewFilter: {
				locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
			},
			monthlyViewFilter: {
				locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
			},
			yearlyViewFilter: {
				locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
			},
		},
	}); */
	const dayDataQuery = useQuery(GET_DAY_DATA, {
		variables: {
			dayViewFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
		},
	});
	const monthDataQuery = useQuery(GET_MONTH_DATA, {
		variables: {
			monthlyViewFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
		},
	});
	const yearDataQuery = useQuery(GET_YEAR_DATA, {
		variables: {
			yearlyViewFilter: {
				//locationids: propertiesQuery.data?.getSensorLocations.map(loc => Number(loc.locationid)),
				locationids: props.properties.map(pro => Number(pro.locationid)),
			},
		},
	});

	/* let ecpAlarms = (multiDataQuery.data?.getEcpAlarms || [])
		.map(alarm => ({
			...alarm,
			...(props.properties.find(
				pro =>
					Number(pro.locationid) ===
					Number(
						(
							props.externalControlPanels.find(
								ecp => Number(ecp.externalcontrolpanelid) === Number(alarm.externalcontrolpanelid)
							) || {}
						).locationid
					)
			) || {}),
			name: alarm.label,
			latesttimestamp: alarm.latesttimestamp || alarm.earliesttimestamp,
			sensorid: ECPALARM_PREFIX + alarm.ecpalarmid,
			type: AlarmType.EcpAlarm,
		}))
		.filter(alarm => alarm.locationid)
		const ecpConfirms = ecpAlarms.filter(alarm => alarm.confirmtimestamp).map(con => ({ ...con, type: AlarmType.EcpConfirm }));
		ecpAlarms = ecpAlarms.filter(alarm => !alarm.confirmtimestamp);
 */
	useEffect(() => {
		props.setPage(PAGES.overview.id);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (props.hasFinishedLoading && !props.sensors.length) {
			setTimeout(() => {
				setIsLoading(false);
			}, 2000); // 2 seconds delay
		}
		// eslint-disable-next-line
	}, [props.hasFinishedLoading]);

	useEffect(() => {
		if (!multiDataQuery.data) return;

		const energySensors = [];

		// Determine the appropriate data view based on the selected interval
		let sensorDataView;
		switch (selectedInterval.label) {
			case i18n.t('constants.year'):
				//sensorDataView = dataQuery.data?.getYearSensordataView;
				sensorDataView = yearDataQuery.data?.getYearSensordataView;
				break;
			case i18n.t('constants.month'):
				//  sensorDataView = dataQuery.data?.getMonthSensordataView;
				sensorDataView = monthDataQuery.data?.getMonthSensordataView;
				break;
			case i18n.t('constants.week'):
				sensorDataView = multiDataQuery.data?.getWeekSensordataView;
				break;
			case i18n.t('constants.day'):
				//sensorDataView = dataQuery.data?.getDaySensordataView;
				sensorDataView = dayDataQuery.data?.getDaySensordataView;
				break;
			default:
				console.error('Invalid interval selected');
				return;
		}
		// Ensure the selected data view is defined before using it
		if (sensorDataView) {
			// Group data by sensorid
			const groupedData = sensorDataView.reduce((acc, sensor) => {
				if (!acc[sensor.sensorid]) {
					acc[sensor.sensorid] = [];
				}
				acc[sensor.sensorid].push(sensor);
				return acc;
			}, {});

			const energySensors = [];

			for (const sensorid in groupedData) {
				const sensorData = groupedData[sensorid];
				// Accessing sensorsQuery.data.getEnergySensorsView instead of props.sensors
				const propsSensor = sensorsQuery.data?.getEnergySensorsView.find(sen => Number(sen.sensorid) === Number(sensorid));

				if (propsSensor) {
					// Create a new object to add the data property
					const sensorWithData = { ...propsSensor };
					//console.log(sensorWithData);

					// Determine the price multiplier based on the classification
					const defaultEnergyPrices = [0.4, 0.3, 0.14];
					const energyPrices = props.userInfo?.energyPrices || defaultEnergyPrices;

					let priceMultiplier;

					const getPriceMultiplier = (index, defaultValue) => {
						const price = energyPrices[index];
						return price !== undefined && price !== 0 ? price : defaultValue;
					};

					if (propsSensor.classification === UNIT_CLASSIFICATIONS.electricity.id) {
						priceMultiplier = getPriceMultiplier(UNIT_CLASSIFICATIONS.electricity.energyIndex, UNIT_CLASSIFICATIONS.electricity.priceMultiplier);
					} else if (propsSensor.classification === UNIT_CLASSIFICATIONS.water.id) {
						priceMultiplier = getPriceMultiplier(UNIT_CLASSIFICATIONS.water.energyIndex, UNIT_CLASSIFICATIONS.water.priceMultiplier);
					} else if (propsSensor.classification === UNIT_CLASSIFICATIONS.districtHeating.id) {
						priceMultiplier = getPriceMultiplier(UNIT_CLASSIFICATIONS.districtHeating.energyIndex, UNIT_CLASSIFICATIONS.districtHeating.priceMultiplier);
					} else if (propsSensor.classification === 'energy') {
						if (propsSensor.subcategory === 'electricity_usage') {
							priceMultiplier = getPriceMultiplier(UNIT_CLASSIFICATIONS.electricity.energyIndex, UNIT_CLASSIFICATIONS.electricity.priceMultiplier);
						} else if (propsSensor.subcategory === 'district_heating_usage') {
							priceMultiplier = getPriceMultiplier(UNIT_CLASSIFICATIONS.districtHeating.energyIndex, UNIT_CLASSIFICATIONS.districtHeating.priceMultiplier);
						} else {
							priceMultiplier = defaultEnergyPrices[0]; // Use the first default value as a fallback
						}
					} else {
						priceMultiplier = defaultEnergyPrices[0]; // Use the first default value as a fallback
					}

					// Map the data to the required format
					sensorWithData.data = sensorData.map(dat => ({
						date: new Date(dat.timestamp),
						priceVal: dat.value * priceMultiplier, // Apply the price multiplier
						unitVal: dat.value,
						isTransformed: {} // Add any additional transformation logic if needed
					}));
					energySensors.push(sensorWithData);
				}
			}

			setEnergySensors(energySensors);
			setAlarms(multiDataQuery.data.getSimpleAlarmView);
			setConfirms(multiDataQuery.data.getConfirms);
			if (!selection.length) {
				setSelection(energySensors.map(sen => ({
					sensorid: sen.sensorid,
					locationid: sen.locationid,
					city: sen.city
				})));
			}
			setIsLoading(false);
		} else {
			console.error('Selected data view is undefined');
		}
	}, [multiDataQuery.data, selectedInterval, props.sensors, props.userInfo, selection.length]);
	return (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateRows: '5rem 4.8rem 28rem auto',
					width: CENTER_CONTENT_STYLE,
					height: `calc(${CENTER_CONTENT_STYLE.height} + 5rem`,
				}}
			>
				{/* Top containers with info snippets */}
				<div style={{ gridRow: '1 / 2', display: 'grid', gridTemplateColumns: '25% 25% 25% auto', margin: '0 0.8rem' }}>
					<OverviewMiniInfo
						label={t('overview.connectedProps')}
						value={
							selection.length === energySensors.length
								? props.properties.length
								// propertiesQuery.data?.getSensorLocations.length || 0
								: [...new Set(selection.map(sel => sel.locationid))].length

							//props.properties.length || 0

						}
						image={require('../assets/dummy-charts/bar-chart.png').default}
						color={colors.secondaryA}
						link={`/${PAGES.properties.id}`}
						style={{ gridColumn: '1 / 2', margin: '0 0.7rem' }}
					/>
					<OverviewMiniInfo
						label={t('overview.alarmCount')}
						value={
							selectedAlarms.filter(alarm => addDays(new Date(), -selectedInterval.days) < new Date(alarm.latesttimestamp))
								.length +
							selectedConfirms.filter(confirm => addDays(new Date(), -selectedInterval.days) < new Date(confirm.timestamp))
								.length// +
							//	ecpAlarms.filter(alarm => addDays(new Date(), -selectedInterval.days) < new Date(alarm.latesttimestamp))
							//		.length +
							//ecpConfirms.filter(confirm => addDays(new Date(), -selectedInterval.days) < new Date(confirm.timestamp))
							//.length
							/* ecpConfirms.filter(alarm => addDays(new Date(), -selectedInterval.days) < new Date(alarm.confirmtimestamp))
								.length */
						}
						image={require('../assets/dummy-charts/line-chart.png').default}
						color={colors.secondaryB}
						link={`/${PAGES.alarms.id}`}
						style={{ gridColumn: '2 / 3', margin: '0 0.7rem' }}
					/>
					<OverviewMiniInfo
						label={t('overview.notAckedAlarmCount')}
						value={
							selectedAlarms.filter(alarm => addDays(new Date(), -selectedInterval.days) < new Date(alarm.latesttimestamp))
								.length //+
							//ecpAlarms.filter(alarm => addDays(new Date(), -selectedInterval.days) < new Date(alarm.latesttimestamp))
							//.length
						}
						image={require('../assets/dummy-charts/pie-chart.png').default}
						color={colors.secondaryC}
						link={`/${PAGES.alarms.id}`}
						style={{ gridColumn: '3 / 4', margin: '0 0.7rem' }}
					/>
					<OverviewMiniInfo
						label={t('overview.noticeCount')}
						value={props.deviations.filter(dev => !dev.dateconfirmed).length}
						image={require('../assets/dummy-charts/line-chart-2.png').default}
						color={colors.secondaryG}
						link={`/${PAGES.deviations.id}`}
						style={{ gridColumn: '4 / 5', margin: '0 0.7rem' }}
					/>
				</div>

				{/* Header with title and time-range */}
				<div style={{ gridRow: '2 / 3', display: 'grid', gridTemplateColumns: 'auto 28%' }}>
					<div style={{ gridColumn: '1 / 2' }}>
						<h1
							style={{
								fontSize: '160%',
								fontWeight: '400',
								whiteSpace: 'nowrap',
								margin: '1.6rem 0 0 1.8rem',
								display: 'flex',
							}}
						>
							{t('overview.cumulativeConsumptionFor')}
							<div onClick={() => setShowSelector(true)} style={{ display: 'flex', cursor: 'pointer' }}>
								<div
									style={{
										maxWidth: '17rem',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontWeight: '450',
										marginLeft: '0.5rem',
										textDecoration: 'underline',
									}}
								>
									{getSelectionLabel(energySensors, selection)}
								</div>
								<ArrowDownIcon style={{ margin: '0.35rem 0 0 0.2rem' }} />
							</div>
						</h1>
					</div>

					<div style={{ gridColumn: '2 / 3' }}>
						<ButtonGroup
							variant='outlined'
							style={{ textTransform: 'none', marginTop: '1.45rem', float: 'right', color: colors.text}}
						>{/* Delete Five years label uncomment the line*/}
							{Object.values(INTERVAL_VALUES).filter(interval => interval !== INTERVAL_VALUES.fiveYears)
								.map(interval => (
									<Button
										onClick={() => setSelectedInterval(interval)}
										key={interval.label}
										style={
											interval.label === selectedInterval.label
												? { textTransform: 'none', color: '#fff', background: colors.primary } //mayve
												: { textTransform: 'none' }
										}
									>
										{interval.label}
									</Button>
								))}
						</ButtonGroup>
					</div>
				</div>

				{/* Graph and money text */}
				<div style={{ gridRow: '3 / 4' }}>
					<CustomDivider disableSideMargin style={{ gridColumn: '1 / 3' }} />

					<div style={{ display: 'grid', gridTemplateColumns: '74% auto', height: '100%' }}>
						{/* Graph */}
						<div style={{ gridColumn: '1 / 2', width: '100%', height: '25rem', margin: '2.3rem -3rem 0rem 0' }}>
							<OverviewChart
								sensors={JSON.parse(JSON.stringify(selectedEnergySensors))}
								interval={selectedInterval}
								maxLegends={5}
								maxLegendLength={14}
							/>
						</div>

						{/* Cost boxes */}
						<div style={{ gridColumn: '2 / 3', height: '100%', display: 'flex' }}>
							<CustomDivider isVertical disableMargin />

							{/* Total cost under duration */}
							<div style={{ margin: '1.4rem 0 0 1.4rem' }}>
								<div
									style={{
										color: '#f8f8f8',
										background: colors.secondaryA,
										borderRadius: '0.3rem',
										padding: '1rem 1rem',
										position: 'relative',
										zIndex: '-1',
									}}
								>
									<div style={{ display: 'flex' }}>
										<h1 style={{ fontWeight: '400', margin: '0' }}>{getFormattedNumber(totalEnergyCost)} </h1>
										<h3 style={{ fontWeight: '400', margin: '0.65rem 0 0 0.5rem' }}>
											{UNIT_CLASSIFICATIONS.electricity.unit}
										</h3>
									</div>
									<h3 style={{ fontWeight: '400', margin: '0' }}>
										{t('overview.duringLast')} {selectedInterval.label.toLowerCase()}.
									</h3>
									<img
										alt='background-chart'
										src={require('../assets/dummy-charts/line-chart-3.png').default}
										style={{
											width: '7rem',
											position: 'absolute',
											bottom: '0.6rem',
											right: '0.8rem',
											zIndex: '-1',
											opacity: '0.18',
										}}
									/>
								</div>

								{/* Per energy type cost under duration */}
								<div
									style={{
										color: '#f8f8f8',
										background: colors.secondaryB,
										borderRadius: '0.3rem',
										padding: '1rem 1rem',
										margin: '1.4rem 0 0 0',
										position: 'relative',
										zIndex: '-1',
									}}
								>
									{drawEnergyCost(
										energyConsumptionCosts.electricity,
										UNIT_CLASSIFICATIONS.electricity.label.toLowerCase(),
										UNIT_CLASSIFICATIONS.electricity.unit
									)}
									{drawEnergyCost(
										energyConsumptionCosts.districtHeating,
										UNIT_CLASSIFICATIONS.districtHeating.label.toLowerCase(),
										UNIT_CLASSIFICATIONS.districtHeating.unit
									)}
									<img
										alt='background-chart'
										src={require('../assets/dummy-charts/pie-chart-2.png').default}
										style={{
											width: '6.2rem',
											position: 'absolute',
											bottom: '0.8rem',
											right: '0.8rem',
											zIndex: '-1',
											opacity: '0.5',
										}}
									/>
								</div>

								{/* Energy type cost modification */}
								<div
									style={{
										color: '#f8f8f8',
										background: colors.secondaryC,
										borderRadius: '0.3rem',
										padding: '1rem 1rem',
										margin: '1.4rem 0 0 0',
										position: 'relative',
									}}
								>
									<h3 style={{ fontSize: '120%', fontWeight: '450', margin: '0 0 0.2rem 0' }}>
										{t('overview.energyPricesTitle')}
									</h3>
									{Object.values(UNIT_CLASSIFICATIONS)
										.slice(0, 3)
										.map(type => (
											<div key={type.id} style={{ display: 'flex' }}>
												<h3 style={{ fontSize: '110%', fontWeight: '400', width: '12rem' }}>
													{type.label} {t('generic.per')} {type.unit}:
												</h3>
												<h3 style={{ fontSize: '110%', fontWeight: '400', width: '2.6rem' }}>
													{props.userInfo?.energyPrices?.[type.energyIndex] || type.priceMultiplier}
												</h3>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Most alarms, negative trends, and positive trends barcharts */}
				<div style={{ gridRow: '4 / 5' }}>
					<CustomDivider disableSideMargin />

					<div style={{ display: 'grid', gridTemplateColumns: '33.3% 33.3% auto' }}>
						{/* Most alarms */}
						<div style={{ gridColumn: '1 / 2' }}>
							<MostAlarmsChart
								selectedInterval={selectedInterval}
								alarms={selectedAlarms}
								properties={props.properties || []}
								//properties={propertiesQuery.data?.getSensorLocations || []}
								style={{ margin: '1.5rem 0 0 1.4rem' }}
							/>
						</div>

						{/* Negative trends */}
						<div style={{ gridColumn: '2 / 3', display: 'flex', height: '100%' }}>
							<CustomDivider isVertical disableMargin />
							<TrendsChart
								selectedInterval={selectedInterval}
								sensors={JSON.parse(JSON.stringify(selectedEnergySensors))}
								style={{ margin: '1.5rem 0 0 1.4rem' }}
							/>
						</div>

						{/* Positive trends */}
						<div style={{ gridColumn: '3 / 4', display: 'flex', height: '100%' }}>
							<CustomDivider isVertical disableMargin />
							<TrendsChart
								negativeTrend
								selectedInterval={selectedInterval}
								sensors={JSON.parse(JSON.stringify(selectedEnergySensors))}
								style={{ margin: '1.5rem 0 0 1.4rem' }}
							/>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				open={Boolean(showSelector)}
				onClose={() => setShowSelector(false)}
				maxWidth={false}
				PaperComponent={props => <>{props.children}</>}
			>
				<EnergySensorSelector
					initialSelection={selection}
					onSave={selection => {
						selection && setSelection(selection);
						setShowSelector(false);
					}}
				/>
			</Dialog>

			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('overview.loadingMsg')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

const OverviewHoc = connect(
	getStateVariables(STORE.hasFinishedLoading, STORE.sensors, STORE.properties, STORE.deviations, STORE.userInfo, STORE.currentProperty, STORE.externalControlPanels),
	{ setPage }
)(Overview);
export { OverviewHoc as Overview };

import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    CircularProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gql, useQuery } from '@apollo/client';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

// this uses the changelogs.get_latest_changelog_details() in the changelog schema in the database, which collects the 5 latest changelogs based on release date.
const GET_CHANGELOG = gql` 
    query ($filter: ChangelogFilter) { 
        getChangelog(filter: $filter) {
            changelog_id
            version
            release_date
            changelog_title
            section_id
            section_title
            section_order
            entry_id
            entry_number
            description
            changelog_created_at
            changelog_updated_at
        }
    }
`;



const addTwoHours = (dateString) => { //this is needed for the date to be the same as in the database
    const date = new Date(dateString);
    date.setHours(date.getHours() + 2);
    return date.toISOString().split('T')[0]; 
};

const addOneHour = (dateString) => { //this is needed for the date to be the same as in the database
    const date = new Date(dateString);
    date.setHours(date.getHours() + 1);
    return date.toISOString().split('T')[0]; 
};

function ChangelogCardView() {
    const current_language = i18n.language;
    const { t } = useTranslation();
    
    const { data, loading, error } = useQuery(GET_CHANGELOG, {
        variables: { filter: { language: current_language } }, 
        onCompleted: console.log('Completed'),
    });
    if (loading) return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ padding:'20px' }}>
            <CircularProgress style={{ width: '50px', height: '50px', margintop: '100px' }} />
            </div>
        </div>
    );
    if (error) {
        console.error("Error fetching changelog:", error);
        return <p>Error: {error.message}</p>;
    }

    // Transform the flat data structure into a nested structure by changelog, section, and entry
    const changelogs = data.getChangelog.reduce((acc, item) => {
        let changelog = acc.find(c => c.changelog_id === item.changelog_id);
        
        if (!changelog) {
            changelog = {
                changelog_id: item.changelog_id,
                version: item.version,
                release_date: item.release_date,
                changelog_title: item.changelog_title,
                changelog_updated_at: item.changelog_updated_at,
                changelog_created_at: item.changelog_created_at,
                sections: [],
            };
            acc.push(changelog);
        }
        
        let section = changelog.sections.find(s => s.section_id === item.section_id);
        if (!section) {
            section = {
                section_id: item.section_id,
                section_title: item.section_title,
                section_order: item.section_order,
                entries: [],
            };
            changelog.sections.push(section);
        }

        section.entries.push({
            entry_id: item.entry_id,
            entry_number: item.entry_number,
            description: item.description,
        });

        return acc;
    }, []);

    const renderChangelogContent = (changelog) => (
        <div style={{minwidth:'90%'}}>
            <h1 style={{ fontWeight: '400', padding: '0.5rem 0 0 0', textAlign: 'center' }}>{changelog.changelog_title}</h1>
            <h3 style={{ fontWeight: '400', padding: '0 0 10px 0', textAlign: 'center' }}>{addTwoHours(changelog.release_date)}</h3>
            <Divider style={{ margin: '0 0.25rem 1rem 0.25rem' }} />
            {changelog.sections.map((section) => (
                <div key={section.section_id}>
                <h3 id={`${section.section_title}`}>
                    {section.section_title}
                </h3>
                <ul style={{ paddingLeft: '2rem', marginLeft: '1rem' }}>
                    {section.entries.map((entry) => (
                        <li key={entry.entry_id} style={{ listStyleType: 'disc', marginBottom: '0.5rem' }}>
                            {entry.description}
                        </li>
                    ))}
                </ul>
                </div>
            ))}
            {changelog.changelog_updated_at && changelog.changelog_updated_at !== changelog.changelog_created_at ? (
                <p>{t('changelog.updatedAt')} {addOneHour(changelog.changelog_updated_at)}</p>
            ) : null}
        </div>
    );

    return (
        <div style={{ marginTop: '3vh' }}>
            {changelogs.map((changelog) => (
                <Accordion key={changelog.changelog_id} style={{ marginTop: '2vh' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${changelog.changelog_id}-content`}
                        id={`panel${changelog.changelog_id}-header`}
                    >
                        <Typography>{`${changelog.changelog_title} - ${addTwoHours(changelog.release_date)}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{display: 'block'}}>
                        {renderChangelogContent(changelog)}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default ChangelogCardView;
import React, { useEffect, useState } from 'react';
import { Button, Step, StepButton, Stepper } from '@material-ui/core';
import SelectBas from './DucAndCurves';
import { connect } from 'react-redux';
import { getStateVariables, STORE } from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import IndoorSensor from './IndoorSensors';
import UtilitySensors from './UtilitySensors';
import Overview from './Overview';
import { MUTATION_ADD_AICONTROLS } from '../../utility-functions/gqlQueries';
import i18n from '../../i18n';

const SETUP_STEPS = {
	BAS: 0,
	INDOOR_SENSORS: 1,
	UTILITY_SENSORS: 2,
	OVERVIEW: 3,
};

const SETUP_NAMES = [i18n.t('generic.bms'), i18n.t('generic.indoorTemp'), i18n.t('generic.utilitySensors'), i18n.t('constants.overview')];

const DEFAULT_MIN_THRESHOLD = 19;
const DEFAULT_MAX_THRESHOLD = 23;

const AI_SYSTEM = '1';

function getAiInputForTemperature(value, name, type) {
	return {
		sensorid: 0,
		sensortype: type,
		name: name,
		communicationtype: 'sensor',
		value: value,
	};
}

function getAiInputForSensor(sensor, type) {
	return {
		sensorid: parseInt(sensor.sensorid),
		sensortype: type,
		name: sensor.name,
		communicationtype: 'sensor',
	};
}

function getAiInputForRegister(register) {
	return {
		sensorid: register.address,
		sensortype: register.parametername,
		name: register.groupname,
		communicationtype: 'modbus',
	};
}

function AiSetupWizard(props) {
	const [step, setStep] = useState(SETUP_STEPS.BAS);
	const [setupOptions, setSetupOptions] = useState({
		bas: {
			bas: undefined,
			controlObject: undefined,
			curves: [],
		},
		indoorTemperature: {
			indoorSensors: [],
			minThreshold: DEFAULT_MIN_THRESHOLD,
			maxThreshold: DEFAULT_MAX_THRESHOLD,
		},
		utilitySensors: {
			supplyTemp: undefined,
			outdoorTemp: undefined,
			electricity: undefined,
			districtHeating: undefined,
		},
	});

	const [addAiControls] = useMutation(MUTATION_ADD_AICONTROLS, {
		onCompleted: ({ addAiControls: success }) => {
			props.closeWizard(success);
		},
		onError: error => {
			props.closeWizard(false);
		},
	});

	const selectedProperty = props.properties.find(property => property.locationid === props.selectedPropertyId);

	const handleNavigation = (step, options) => {
		setStep(step);
		setSetupOptions({ ...setupOptions, ...options });
	};

	const handleFinish = () => {
		const { bas, indoorTemperature, utilitySensors } = setupOptions;

		function incorrectOptions() {
			return (
				bas?.bas === undefined ||
				[undefined, 0].includes(bas?.curves?.length) ||
				[undefined, 0].includes(indoorTemperature?.indoorSensors?.length) ||
				utilitySensors?.supplyTemp === undefined ||
				utilitySensors?.outdoorTemp === undefined
			);
		}

		if (incorrectOptions()) {
			console.error('Incorrect options');
			props.closeWizard(false);
			return;
		}

		const { electricity, districtHeating } = utilitySensors;

		const aiControls = {
			aiControls: {
				locationid: selectedProperty.locationid,
				system: AI_SYSTEM,
				ducid: bas.bas.ducid,
				sensors: [
					...bas.curves.map(curve => getAiInputForRegister(curve)),
					...indoorTemperature.indoorSensors.map(sensor => getAiInputForSensor(sensor, 'indoortemp')),
					getAiInputForSensor(utilitySensors.supplyTemp, 'supplytemp'),
					getAiInputForSensor(utilitySensors.outdoorTemp, 'outdoortemp'),
					getAiInputForTemperature(indoorTemperature.minThreshold, 'minimumtemp', 'mintemp'),
					getAiInputForTemperature(indoorTemperature.minThreshold, 'maximumtemp', 'maxtemp'),
					...(electricity ? [getAiInputForSensor(electricity, 'electricity')] : []),
					...(districtHeating ? [getAiInputForSensor(districtHeating, 'districtHeating')] : []),
				],
			},
		};

		addAiControls({
			variables: aiControls,
		});
	};

	const handleCancel = () => {
		props.closeWizard(true);
	};

	return (
		<>
			<Stepper activeStep={step} style={{ margin: '0 2.5rem 0 1rem' }}>
				{SETUP_NAMES.map((label, index) => (
					<Step key={label}>
						<StepButton onClick={() => setStep(index)}>{label}</StepButton>
					</Step>
				))}
			</Stepper>

			{step === SETUP_STEPS.BAS ? (
				<SelectBas
					property={selectedProperty}
					options={setupOptions.bas}
					onCancel={handleCancel}
					onContinue={options => handleNavigation(SETUP_STEPS.INDOOR_SENSORS, { bas: options })}
				/>
			) : undefined}
			{step === SETUP_STEPS.INDOOR_SENSORS ? (
				<IndoorSensor
					property={selectedProperty}
					options={setupOptions.indoorTemperature}
					onContinue={options => handleNavigation(SETUP_STEPS.UTILITY_SENSORS, { indoorTemperature: options })}
					onBack={() => handleNavigation(SETUP_STEPS.BAS)}
				/>
			) : undefined}
			{step === SETUP_STEPS.UTILITY_SENSORS ? (
				<UtilitySensors
					property={selectedProperty}
					options={setupOptions.utilitySensors}
					onContinue={options => handleNavigation(SETUP_STEPS.OVERVIEW, { utilitySensors: options })}
					onBack={() => handleNavigation(SETUP_STEPS.INDOOR_SENSORS)}
				/>
			) : undefined}
			{step === SETUP_STEPS.OVERVIEW ? (
				<Overview
					property={selectedProperty}
					options={setupOptions}
					onFinish={handleFinish}
					onBack={() => handleNavigation(SETUP_STEPS.UTILITY_SENSORS)}
				/>
			) : undefined}
		</>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.externalControlPanels))(AiSetupWizard);

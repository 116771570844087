import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';

import { PAGES, AI_TABS } from '../constants';
import { STORE, getStateVariables } from '../redux/selectors';
import { setPage, setProperty, addCallback, removeCallback } from '../redux/actionCreators';
import SimplePageWrapper from '../components/SimplePageWrapper';
import CustomTabs from '../components/CustomTabs';
import PropertyListAutomation from '../components/PropertyListAutomation';
import { combinePaths } from '../utility-functions';
import Curve from '../components/Curve';
import IndoorSensor from '../components/IndoorSensor';
import AlarmList from '../components/AlarmList';
import EnergyPerformance from '../components/EnergyPerformance';
import AiControl from '../components/AiControl';
import Results from '../components/Results';
import AiOverview from '../components/automation/AiOverview';
import AiFolderWrapper from '../components/automation/AiFolderWrapper';

function Automation(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedSensors, setSelectedSensors] = useState([]);

	const { t } = useTranslation();

	const [selectedProperty, setSelectedProperty] = useState();

	useEffect(() => {
		props.setPage(PAGES.properties.id);

		props.addCallback('on-sidebar-property-click', 'remove-selected-property', () => setPropertySelection(undefined, false));
		return () => props.removeCallback('on-sidebar-property-click', 'remove-selected-property');
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		props.setPage(PAGES.automation.id);
		// eslint-disable-next-line
	}, []);

	// Update the properties whenever the list of properties in Redux change and select property specified in URL
	useEffect(() => {
		// Select location id in URL
		// 0: Empty, 1: Page id, 2: Location id, 3: Property tab
		const urlParts = props.location.pathname.split('/');
		if (urlParts[2] && !selectedProperty)
			setPropertySelection(
				props.properties.find(property => property.locationid === Number(urlParts[2])),
				false
			);

		// Wait until properties have loaded, then change property selection to correspond with the URL
		if (props.properties.length) {
			let locationIdInUrl = props.history.location.pathname.split('/')[2];
			if (!isNaN(locationIdInUrl)) locationIdInUrl = Number(locationIdInUrl);

			const propertyInUrl = props.properties.find(property => property.locationid === locationIdInUrl);

			if (locationIdInUrl !== selectedProperty?.locationid)
				setPropertySelection(propertyInUrl, !propertyInUrl && locationIdInUrl ? true : false);
		}
		// eslint-disable-next-line
	}, [props.properties, props.location]);

	function setPropertySelection(property, changeUrl = true) {
		if (property) {
			const selectedSensors = props.sensors.filter(sen => sen.locationid === property.locationid);

			setSelectedProperty(property);
			setSelectedSensors(selectedSensors);

			props.setProperty(property.locationid);

			if (changeUrl) props.history.push('/' + PAGES.automation.id + '/' + property.locationid);
		} else {
			setSelectedProperty(undefined);
			setSelectedSensors([]);

			props.setProperty('');

			if (changeUrl) props.history.push('/' + PAGES.automation.id);
		}
	}

	const propertyOptions = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));

	useEffect(() => {
		setSelectedProperties(propertyOptions.filter(pro => pro.label.includes('Karlskrona:')));
		// eslint-disable-next-line
	}, [props.properties]);

	if (selectedProperty) {
		const urlUpToTab = combinePaths(PAGES.automation.id, props.currentProperty);
		return (
			<SimplePageWrapper title={selectedProperty.street}>
				<CustomTabs
					tabs={Object.values(AI_TABS)
						.map(tab => ({
							...tab,
						}))}
					regexpBeforeTab={`/${PAGES.automation.id}/[0-9]+`}
					squeezeFactor={0.5}
				/>

				<Switch>
					<Route exact path={[urlUpToTab, combinePaths(urlUpToTab, AI_TABS.results.id)]}>
						<AiFolderWrapper property={selectedProperty?.locationid} />
					</Route>
					<Route path={combinePaths(urlUpToTab, AI_TABS.indoorSensor.id)} component={IndoorSensor} />
					{/* <Route path={combinePaths(urlUpToTab,AI_TABS.energyPerformance.id)} component={EnergyPerformance} /> */}
					<Route path={combinePaths(urlUpToTab, AI_TABS.aiControl.id)} component={AiControl} />
					{/* <Route path={combinePaths(urlUpToTab, AI_TABS.curve.id)} component={Curve} /> */}
					{/* <Route path={combinePaths(urlUpToTab, AI_TABS.alarmHistory.id)} render={() => <AlarmList isPropertySpecific />} /> */}
				</Switch>
			</SimplePageWrapper>
		);
	} else {
		return <PropertyListAutomation properties={props.properties} onPropertySelect={property => setPropertySelection(property)} />;
	}
}

export default connect(getStateVariables(STORE.sensors, STORE.properties, STORE.currentProperty, STORE.userInfo), {
	setPage,
	setProperty,
	addCallback,
	removeCallback,
})(Automation);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CENTER_CONTENT_STYLE } from '../constants';
import { IconButton, Button, Typography, Paper } from '@material-ui/core';
import { Fullscreen as FsIcon, FullscreenExit as FsExitIcon, OpenInNew as NewTabIcon } from '@material-ui/icons';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { makeStyles } from '@material-ui/core/styles';

import * as colors from '../colors';

const useStyles = makeStyles((theme) => ({
    redirectContainer: {
        width: `calc(${CENTER_CONTENT_STYLE.width} - 4rem)`,
        height: '4rem',
        margin: '0 auto 2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1),
    },
    redirectMessage: {
        color: theme.palette.primary.contrastText,
		//add padding the left to center the text
		paddingLeft: '1rem',
    },
    button: {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
    },
}));

/**
 * Embedded external control-panel view
 * @param {object} ecp : The external control-panel to be displayed
 * @returns
 */
function ExternalControlPanel(props) {
	const [isFs, setIsFs] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [modifiedUrl, setModifiedUrl] = useState(props.ecp.url);
	const [isFirefox, setIsFirefox] = useState(false);
	const [credentialsRemoved, setCredentialsRemoved] = useState(false);
	const fsHandle = useFullScreenHandle();
	const { t } = useTranslation();

	const classes = useStyles();

	useEffect(() => {
		// Detect if the browser is Firefox
		const userAgent = navigator.userAgent.toLowerCase();
		setIsFirefox(userAgent.includes('firefox'));

		// Check if the URL contains embedded credentials
		const url = props.ecp.url;
		const hasEmbeddedCredentials = /^(https?:\/\/)([^:@]+:[^@]+@)/.test(url);

		// Show the warning only if the credentials haven't been removed yet and it's not Firefox
		if (hasEmbeddedCredentials && !credentialsRemoved) {
			setShowWarning(true);
		}

		if (isFs && !fsHandle.active) fsHandle.enter();
		else if (!isFs && fsHandle.active) fsHandle.exit();
		// eslint-disable-next-line
	}, [isFs, props.ecp.url, credentialsRemoved]);

	const ecpSrc =
		window.location.host.includes('meliox.') && modifiedUrl.includes('melioxdev.')
			? modifiedUrl.replace('melioxdev.', 'meliox.')
			: window.location.host.includes('melioxdev.') && modifiedUrl.includes('meliox.')
				? modifiedUrl.replace('meliox.', 'melioxdev.')
				: modifiedUrl;

	const redirect = (ecpSrc.includes('http://') || ecpSrc.includes('https://app.enspire.danfoss.com'))
	const isAbelko = ecpSrc.includes('/login.fcgi');

	const handleWarningButtonClick = () => {
		// Open the URL in a new tab
		const newTab = window.open(modifiedUrl, '_blank');
		if (newTab) {
			// Close the tab after 3 seconds
			setTimeout(() => {
				newTab.close();
				// Remove credentials from the URL
				const urlWithoutCredentials = modifiedUrl.replace(/^(https?:\/\/)([^:@]+:[^@]+@)/, '$1');
				setModifiedUrl(urlWithoutCredentials);
				setShowWarning(false);
				setCredentialsRemoved(true);
			}, 5000);
		}
	};

	return (
		<>
		{!redirect && (
			<div
				style={{
					width: `calc(${CENTER_CONTENT_STYLE.width} - 4rem)`,
					height: isAbelko ? '17rem' : '40rem',
					margin: '0 auto 2rem',
					position: 'relative',
				}}
			>
				<FullScreen handle={fsHandle} onChange={state => setIsFs(state)}>
					{!isFirefox && showWarning && (
						<div
							style={{
								position: 'absolute',
								background: '#ffdddd',
								borderRadius: '.5rem',
								padding: '1.5rem',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								boxShadow: '0 0 0.5rem #0003',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div>{t('externalControlPanel.verificationNeeded')}</div>
							<Button
								variant='outlined'
								onClick={handleWarningButtonClick}
								style={{ marginTop: '0.8rem', color: colors.primary }}
							>
								{t('externalControlPanel.clickToVerify')}
							</Button>
						</div>
					)}

					{isAbelko && (
						<div
							style={{
								position: 'absolute',
								background: '#fff',
								borderRadius: '.5rem',
								padding: '1.5rem',
								top: '4.6rem',
								left: '18rem',
								boxShadow: '0 0 0.5rem #0003',
							}}
						>
							<div>{t('externalControlPanel.bmsOpenNewWindowPromt')}</div>
							<Button
								variant='outlined'
								onClick={() => window.open(ecpSrc, '_blank').focus()}
								style={{ margin: '0.8rem auto auto 5.0rem', color: colors.primary }}
							>
								{t('externalControlPanel.openBms')}
							</Button>
						</div>
					)}

					<IconButton
						onClick={() => window.open(ecpSrc, '_blank').focus()}
						style={{
							position: 'absolute',
							top: '2.1rem',
							right: '2.5rem',
							width: '2.5rem',
							height: '2.5rem',
							color: '#000c',
						}}
					>
						<NewTabIcon style={{ transform: 'scale(0.9)' }} />
					</IconButton>
					<IconButton
						onClick={() => setIsFs(!isFs)}
						style={{
							position: 'absolute',
							top: '2.1rem',
							right: '0.0rem',
							width: '2.5rem',
							height: '2.5rem',
							color: '#000c',
						}}
					>
						{isFs ? <FsExitIcon style={{ transform: 'scale(1.2)' }} /> : <FsIcon style={{ transform: 'scale(1.2)' }} />}
					</IconButton>

					<iframe
						title={`External Control-Panel ${props.ecp.externalcontrolpanelid}`}
						src={ecpSrc}
						sandbox='allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'
						style={{
							width: '100%',
							height: isFs ? '100%' : isAbelko ? '17rem' : '40rem',
							boxShadow: '0 0 0.5rem #0003',
							borderRadius: '0.3rem',
						}}
						frameBorder='0'
						allowFullScreen
					></iframe>
				</FullScreen>
			</div>
		)}
		{redirect && (
			<Paper className={classes.redirectContainer}>
				<Typography className={classes.redirectMessage}>
					{t('externalControlPanel.redirectMessage')}
				</Typography>
				<Button
					variant="outlined"
					onClick={() => {
						console.log('redirecting to: ' + ecpSrc);
						window.open(ecpSrc, '_blank').focus();
					}}
					className={classes.button}
				>
					{t('externalControlPanel.openInNewTab')}
				</Button>
			</Paper>
            )}
	</>
	);
	
}

export default ExternalControlPanel;

import React, { useState, useEffect } from 'react';
import { makeStyles, Tabs, Tab, Paper, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UNIT_CLASSIFICATIONS, CLASSIFICATIONS} from '../constants';
import { getEnergySensors as _getEnergySensors } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import SelectionTable from './SelectionTable';
import * as colors from '../colors';
import i18n from '../i18n';

const TABS = {
	cities: { label: i18n.t('generic.city_other'), value: 'cities' },
	properties: { label: i18n.t('constants.properties'), value: 'properties' },
	sensors: { label: i18n.t('energySensorSelector.energySensor'), value: 'sensors' },
};

const CONTAINER_SHADOW = '0 0.1rem 0.5rem #0005';

/**
 * Draws tabs and sensor-tables for selecting energy-properties
 * @param {{sensorid, locationid, city}[]} initialSelection : Sensors to be selected at component initialization
 * @param {function(selection: {sensorid, locationid, city}[] | undefined)} onSave : Called when a selection has been made or when the user wants to stop selecting
 */
function EnergySensorSelector(props) {
	const [selectedTabId, setSelectedTabId] = useState(TABS.cities.value);
	const [cities, setCities] = useState([]);
	const [selections, setSelections] = useState(props.initialSelection || []); // {city, locationid, sensorid}[]
	const [selectionCounts, setSelectionCounts] = useState({});

	const getEnergySensors = rule => _getEnergySensors(props.sensors, rule);

	useEffect(() => {
		const cities = [];
		for (const pro of props.properties || []) {
			const city = cities.find(cit => cit.city === pro.city);
			if (city) city.properties.push(pro);
			else cities.push({ ...pro, properties: [pro] });
		}
		setCities(cities);
	}, [props.properties, props.sensors]);

	useEffect(() => {
		setSelectionCounts({
			[TABS.cities.value]: [...new Set(selections.map(sel => sel.city))].length,
			[TABS.properties.value]: [...new Set(selections.map(sel => sel.locationid))].length,
			[TABS.sensors.value]: [...new Set(selections.map(sel => sel.sensorid))].length,
		});
	}, [selections]);

	// @param {{added: any[], removed: any[]}} diff, {TABS.-.value} type
	function updateSelection(diff, type) {
		let sel = [...selections];
		let removeFun = () => true;
		let addFun = () => false;

		if (type === TABS.cities.value) {
			removeFun = sel => !diff.removed.map(cit => cit.city).includes(sel.city);
			addFun = sen => diff.added.map(cit => cit.city).includes(sen.city);
		} else if (type === TABS.properties.value) {
			removeFun = sel => !diff.removed.map(pro => pro.locationid).includes(sel.locationid);
			addFun = sen => diff.added.map(pro => pro.locationid).includes(sen.locationid);
		} else if (type === TABS.sensors.value) {
			removeFun = sel => !diff.removed.map(sen => sen.sensorid).includes(sel.sensorid);
			addFun = sen => diff.added.map(sen => sen.sensorid).includes(sen.sensorid);
		}

		if (diff.removed.length) sel = sel.filter(removeFun);
		if (diff.added.length)
			sel = sel.concat(
				getEnergySensors(addFun).map(sen => ({
					city: sen.city,
					locationid: sen.locationid,
					sensorid: sen.sensorid,
				}))
			);
		setSelections(sel);
	}

	const { t } = useTranslation();

	const cityTableProps = {
		data: cities.map(cit => ({
			...cit,
			propertyCount: cit.properties.length,
			energySensorCount: getEnergySensors(sen => sen.city === cit.city).length,
		})),
		dataId: 'city',
		selectedIds: selections.map(sel => sel.city),
		disabledIds: cities.filter(cit => !getEnergySensors(sen => sen.city === cit.city).length).map(cit => cit.city),
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('constants.properties'), field: 'propertyCount' },
			{
				title: t('energySensorSelector.energySensor'),
				field: 'energySensorCount',
			},
		],
	};
	const propTableProps = {
		data: props.properties.map(pro => ({
			...pro,
			energySensorCount: getEnergySensors(sen => sen.locationid === pro.locationid).length,
		})),
		dataId: 'locationid',
		selectedIds: selections.map(sel => sel.locationid),
		disabledIds: props.properties
			?.filter(pro => !getEnergySensors(sen => sen.locationid === pro.locationid).length)
			.map(pro => pro.locationid),
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('generic.address'), field: 'street' },
			{
				title: t('energySensorSelector.energySensor'),
				field: 'energySensorCount',
			},
		],
	};
	const senTableProps = {
		data: getEnergySensors().map(sen => ({
			...sen,
			classification: 
				Object.values(UNIT_CLASSIFICATIONS).find(cla => cla.id === sen.classification)?.label || 
				Object.values(CLASSIFICATIONS).find(cla => cla.subcategories[sen.subcategory])?.subcategories[sen.subcategory].label.split(' ').slice(0, -1).join(' ') ||
				Object.values(UNIT_CLASSIFICATIONS).find(cla => cla.id === sen.subcategory)?.label ||
				'',
		})),
		dataId: 'sensorid',
		selectedIds: selections.map(sel => sel.sensorid),
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('generic.address'), field: 'street' },
			{
				title: t('energySensorSelector.energyType'),
				field: 'classification',
				maxLength: 30,
			},
		],
	};

	return (
		<div style={{ display: 'flex', maxHeight: '500px', overflow: 'hidden' }}>
			<div style={{ flex: 1, overflowY: 'auto' }} >
				<SelectionTable
					{...(selectedTabId === TABS.cities.value
						? cityTableProps
						: selectedTabId === TABS.properties.value
							? propTableProps
							: senTableProps)}
					localization={{ title: '' }}
					pageSizeOptions={[10, 15, 20]}
					showPagination
					onSelectionChangeDiff={diff => updateSelection(diff, selectedTabId)}
					style={{ minWidth: '31rem', minHeight: 'auto', overflowY: 'auto', boxShadow: CONTAINER_SHADOW }}
					tableProps={{
						maxColumnLength: 25,
						toolbarHeight: '48px',
						options: { pageSize: 10 },
					}}
				/>
			</div>

			<div style={{ marginLeft: '1rem', position: 'relative' }}>
				<Paper style={{ boxShadow: CONTAINER_SHADOW }}>
					<Tabs
						orientation='vertical'
						indicatorcolor={colors.text}
						value={selectedTabId}
						onChange={(e, i) => setSelectedTabId(i)}
						classes={makeStyles({
							indicator: {
								width: '6px',
								height: '38px !important',
								margin: '5px 0 5px 0',
								borderRadius: '0 9px 9px 0',
								right: 'unset',
							},
						})()}
					>
						{Object.values(TABS).map(tab => (
							<Tab {...tab} label={`${tab.label} (${selectionCounts[tab.value] || 0})`} key={tab.value} />
						))}
					</Tabs>
				</Paper>

				<Paper
					style={{
						width: '100%',
						position: 'absolute',
						bottom: '0',
						padding: '0.6rem',
						display: 'grid',
						gap: '0.5rem',
						boxShadow: CONTAINER_SHADOW,
					}}
				>
					<Button onClick={() => props.onSave?.()} style={{ color: '#0008', boxShadow: '0 1px 0.2rem #0004' }}>
						{t('generic.cancel')}
					</Button>
					<Button
						disabled={!selections.length}
						onClick={() => props.onSave?.(selections)}
						style={{ boxShadow: '0 1px 0.2rem #0004', color: colors.text}}
					>
						{t('generic.save')}
					</Button>
				</Paper>
			</div>
		</div>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.properties))(EnergySensorSelector);

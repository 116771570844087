import { addDays, addHours, addMonths, addYears } from 'date-fns';

export function addInterval(date, interval, floor = false, multiplier = 1) {
	let retDate = new Date(date);
	if (floor && interval !== 'hour') {
		retDate.setHours(0, 0, 0, 0);
	} else if (floor && interval === 'hour') {
		retDate.setMinutes(0, 0, 0);
	}
	switch (interval) {
		case 'hour':
			retDate = addHours(retDate, 1 * multiplier);
			break;
		case 'day':
			retDate = addDays(retDate, 1 * multiplier);
			break;
		case 'week':
			retDate = addDays(retDate, 7 * multiplier);
			if (floor) {
				retDate.setDate(retDate.getDate() - 1 - (retDate.getDay() % 7));
			}
			break;
		case 'month':
			retDate = addMonths(retDate, multiplier);
			if (floor) {
				retDate.setDate(1);
			}
			break;
		case 'year':
			retDate = addYears(retDate, multiplier);
			if (floor) {
				retDate.setMonth(0);
				retDate.setDate(1);
			}
			break;
		case 'fiveYears':
			retDate = addYears(retDate, 5 * multiplier);
			if (floor) {
				retDate.setMonth(0);
				retDate.setDate(1);
			}
			break;
		default:
			break;
	}
	return retDate;
}

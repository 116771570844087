import React from 'react';
import getGraphTypeForUnit from '../../utility-functions/getGraphType';
import { GRAPH_INTERVAL_TYPES, GRAPH_TYPES } from '../../constants';
import { HistoryBarChart, HistoryLineChart } from '../NivoCharts';

function formatData(data, graphType) {
	if (graphType === GRAPH_TYPES.line) {
		if (!data || !data.length) return [];
		const sensor = data[0];
		return [
			{
				id: GRAPH_INTERVAL_TYPES.current,
				data: sensor.sensordata
					.filter(point => point !== undefined)
					.map(({ x, y }) => ({
						x,
						y: sensor.multiplier && y ? y * sensor.multiplier : y,
					})),
			},
		];
	}
	return data.reduce(
		(acc, sen) => [
			...acc,
			...sen.sensordata.map(({ x, y }) => {
				const dataY = sen.multiplier && y ? y * sen.multiplier : y;

				return {
					Datum: x,
					[GRAPH_INTERVAL_TYPES.current]: dataY,
					[GRAPH_INTERVAL_TYPES.former]: null,
					comparisonDate: '-',
				};
			}),
		],
		[]
	);
}

function OverviewGraph({ style, unit, data, interval, error, ...props }) {
	const graphType = getGraphTypeForUnit(unit);
	const graphData = formatData(data, graphType);
	return (
		<div style={{ height: '100%', width: '100%', ...style }}>
			{graphType === GRAPH_TYPES.line ? (
				<HistoryLineChart sensorInfo={{ unit }} data={graphData} {...props} />
			) : (
				<HistoryBarChart sensorInfo={{ unit }} data={graphData} {...props} />
			)}
		</div>
	);
}

export default OverviewGraph;

import React, { useState, useRef, useEffect } from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Button, Backdrop, LinearProgress } from '@material-ui/core';
import { CheckCircle as CheckIcon, Edit as EditIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import Select from 'react-select';
import { makeStyles } from '@material-ui/core';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Checkbox } from '@material-ui/core';
//import Tooltip from '@mui/material/Tooltip';
//import IconButton from '@mui/material/IconButton';
//import { Help as HelpIcon } from '@material-ui/icons';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { createDeepCopy, editObject } from '../utility-functions';
import { STORE, getStateVariables } from '../redux/selectors';
import { setSensors, setSensorGroups } from '../redux/actionCreators';
import { UNIT_CLASSIFICATIONS, PROP_SELECTION_TYPES, CLASSIFICATIONS } from '../constants';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import DialogWrapper from './DialogWrapper';
import AlarmPeriodEditor from './AlarmPeriodEditor';
import { ConfirmPopup } from './ConfirmPopup';
import * as colors from '../colors'
import { fil, id, sk } from 'date-fns/locale';
import { set } from 'date-fns';
import { use } from 'i18next';

const SENSOR_TYPE = Object.freeze({ registered: 'registered', unregistered: 'unregistered' });
const SIDEBAR_STYLE = { width: '12rem', margin: '0.05rem 0 0 1rem' };

const GET_SENSORS = gql`
	query ($filter: SensorFilter) {
		getSensors(filter: $filter) {
			sensorid
			sensorref
		}
	}
`;

const GET_SENSOR_DATA = gql`
	query {
		getSensorView {
			sensorid
			sensorref
			name
			unit
			classification
			subcategory
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			includeintotal
			accumulateddata
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
			setpoint
		}
	}
`;
const GET_SENSOR_VIEW = gql`
	query ($filter: SensorViewFilter) {
		getSensorView(filter: $filter) {
			sensorid
			sensorref
			name
			unit
			classification
			subcategory
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			includeintotal
			accumulateddata
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
			setpoint
		}
	}
`;
const SET_SENSORS = gql`
	mutation (
		$sensorids: [ID]!
		$sensorref: String
		$name: String
		$locationid: Int
		$sensorgroupid: Int
		$classification: String
		$subcategory: String
		$unit: String
		$multiplier: Float
		$includeintotal: Boolean
		$accumulateddata: Boolean
		$alarmgraceminutes: Int
		$setpoint: Boolean
	) {
		setSensors(
			sensorids: $sensorids
			sensorref: $sensorref
			name: $name
			locationid: $locationid
			sensorgroupid: $sensorgroupid
			classification: $classification
			subcategory: $subcategory
			unit: $unit
			multiplier: $multiplier
			includeintotal: $includeintotal
			accumulateddata: $accumulateddata
			alarmgraceminutes: $alarmgraceminutes
			setpoint: $setpoint
		) {
			sensorid
			sensorref
			name
			locationid
			sensorgroupid
			classification
			subcategory
			unit
			multiplier
			includeintotal
			accumulateddata
			alarmgraceminutes
			setpoint
		}
	}
`;

const ADD_SENSORGROUP = gql`
	mutation ($name: String!, $locationid: Int!) {
		addSensorGroup(name: $name, locationid: $locationid) {
			sensorgroupid
			name
			locationid
		}
	}
`;
/* const REMOVE_SENSORS = gql`
	mutation ($sensorids: [ID]) {
		removeSensors(sensorids: $sensorids) {
			sensorid
			locationid
		}
	}
`; */

const ADD_SENSOR = gql`
	mutation ($name: String, $locationid: Int, $sensorref: String) {
		addSensor(name: $name, locationid: $locationid, sensorref: $sensorref) {
			sensorref
			name
			locationid
			classification
		}
	}
`;

const POPUP_TYPES = Object.freeze({ edit: 1, delete: 2 });

// create a standard textfield settings object
export function textFieldWrapper(onChange, placeholder) {
	return (
		<TextField
			type={'text'}
			margin='dense'
			variant='outlined'
			fullWidth
			multiline={false}
			rows={2}
			rowsMax={15}
			inputProps={{ style: { fontSize: '86%' } }}
			style={{ margin: '0' }}
			onChange={onChange}
			placeholder={placeholder}
		/>
	);
};


/**
 * Two tables for unregistered and registered sensors that allow them to be filtered and bulk-edited
 */
function SensorAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [unregSelectedProperties, setUnregSelectedProperties] = useState([]);
	const [selectedSensors, setSelectedSensors] = useState([]);
	const [unregSelectedSensors, setUnregSelectedSensors] = useState([]);
	const [sensorsUnderEdit, setSensorsUnderEdit] = useState([]);
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [unregSensors, setUnregSensors] = useState([]);
	const [showAlarmPeriodPopup, setShowAlarmPeriodPopup] = useState(false);
	const [apCb, setApCb] = useState();
	const [editPopupSelectedPropertyId, setEditPopupSelectedPropertyId] = useState();
	const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
	const [sensoridsToDelete, setSensoridsToDelete] = useState([]);
	const sensorGroupQueue = useRef([]).current; // Format: {name: string, locationid: number, sensorIds: object[]}
	const [isLoading, setIsLoading] = useState(true);
	const [editPopupType, setEditPopupType] = useState();
	const [refresh, setRefresh] = useState(0);

	const [sensorData, setSensorData] = useState([]);
	const [exportedData, setExportedData] = useState([]);

	const [sensorName, setSensorName] = useState();
	const [sensorClass, setSensorClass] = useState();
	const [sensorUnit, setSensorUnit] = useState();
	const [sensorSpecificType, setSensorSpecificType] = useState();
	const [sensorMultiplier, setSensorMultiplier] = useState();
	const [accumulatedData, setAccumulatedData] = useState();
	const [alarmgraceminutes, setAlarmgraceminutes] = useState();
	const [sensorGroups, setSensorGroups] = useState();
	const [sensorIncludedInTotal, setSensorIncludedInTotal] = useState();
	const [setPoint, setSetPoint] = useState();
	
	const [sensorExpanded, setSensorExpanded] = useState(false);

	const SENSOR_TYPES = CLASSIFICATIONS;

	const { t } = useTranslation();
	const { data } = useQuery(GET_SENSOR_DATA);

	useEffect(() => {
		if (data && data.getSensorView) {
			setSensorData(data.getSensorView);
			// setExportedData(data.getSensorView);
		}
	}, [data]);

	useEffect(() => {
		if (exportedData.length > 0) {
			// Download the Excel file when the exportedData is available
			exportToExcel(exportedData);
		}
	}, [exportedData]);

	useEffect(() => {
		if (editPopupEnabled) setEditPopupSelectedPropertyId(sensorsUnderEdit[0].locationid);
		// eslint-disable-next-line
	}, [editPopupEnabled]);

	useQuery(GET_SENSORS, {
		variables: { filter: { locationids: [null] } },
		skip: props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id || unregSensors.length,
		onCompleted: ({ getSensors }) => setUnregSensors(createDeepCopy(getSensors)),
	});
	const [getSensorView] = useLazyQuery(GET_SENSOR_VIEW, {
		onCompleted: ({ getSensorView }) => {
			for (const newSen of getSensorView) {
				const oldSen = props.sensors.find(sen => sen.sensorid === newSen.sensorid);
				if (oldSen) editObject(oldSen, newSen);
				else props.sensors.push(newSen);
			}
			props.setSensors([...props.sensors]);
		},
	});
	const [setSensors] = useMutation(SET_SENSORS, {
		onCompleted: ({ setSensors }) => {

			if (!setSensors) return;

			const updatedSensors = setSensors.filter(sen => sen.locationid); //find sensors with the locationid
			let newSensorIds = [];
			for (const newSen of updatedSensors) { //find all possible updated sensors
				const oldSen = props.sensors.find(sen => sen.sensorid === newSen.sensorid); //find the specific sensor in props
				if (oldSen) {  //if the sensor exists previously
					if (oldSen.locationid !== newSen.locationid)
						editObject(
							oldSen,
							props.properties.find(pro => pro.locationid === newSen.locationid)
						);
					if (oldSen.sensorgroupid !== newSen.sensorgroupid)
						oldSen.groupname = props.sensorGroups.find(grp => grp.sensorgroupid === newSen.sensorgroupid)?.groupname;
					if (oldSen.multiplier !== newSen.multiplier) oldSen.value *= newSen.multiplier / oldSen.multiplier;
					editObject(oldSen, newSen);
				} else {
					newSensorIds.push(newSen.sensorid);
					const unregSenI = unregSensors.findIndex(sen => sen.sensorid === newSen.sensorid);
					if (unregSenI !== -1) unregSensors.splice(unregSenI, 1);
				}
			}

			const updatedUnregSensors = setSensors.filter(sen => !sen.locationid);
			const previousUnregSenCount = unregSensors.length;
			for (const newSen of updatedUnregSensors) {
				const oldSen = unregSensors.find(sen => sen.sensorid === newSen.sensorid);
				if (oldSen) editObject(oldSen, newSen);
				else {
					unregSensors.push(newSen);
					const senI = props.sensors.findIndex(sen => sen.sensorid === newSen.sensorid);
					if (senI !== -1) props.sensors.splice(senI, 1);
				}
			}

			if (updatedSensors.length || previousUnregSenCount !== unregSensors.length) props.setSensors([...props.sensors]);
			if (newSensorIds.length) getSensorView({ variables: { filter: { sensorids: newSensorIds } } });
			setUnregSensors([...unregSensors]);
		},
	});
	const [addSensorGroup] = useMutation(ADD_SENSORGROUP, {
		onCompleted: ({ addSensorGroup }) => {
			props.setSensorGroups([...props.sensorGroups, addSensorGroup]);

			const queueI = sensorGroupQueue.findIndex(
				grp => grp.name === addSensorGroup?.name && grp.locationid === addSensorGroup?.locationid
			);
			if (queueI !== -1)
				setSensors({
					variables: {
						sensorids: sensorGroupQueue.splice(queueI, 1)[0]?.sensorIds,
						sensorgroupid: Number(addSensorGroup?.sensorgroupid),
					},
				});
		},
	});

	const [addSensor] = useMutation(ADD_SENSOR, {
		onCompleted: () => {
			console.log('');
		},
		onError: (error) => {
			console.log('Error adding sensor: ', error);
		}
	});

	function onSensorClick(sensor) {
		setSensorsUnderEdit([sensor]);
		setEditPopupEnabled(!editPopupEnabled);
	}

	function ifAllSame(selectedSensors, key) {
		return selectedSensors.every(sen => sen[key] === selectedSensors[0][key]);
	}

	function onEditButtonClick(sensorType) {
		if (sensorType === SENSOR_TYPE.registered) setSensorsUnderEdit(selectedSensors);
		else setSensorsUnderEdit(unregSelectedSensors);
		setEditPopupEnabled(true);

		if (selectedSensors.length) {
			if (ifAllSame(selectedSensors, 'classification')) {
				if (SENSOR_TYPES[selectedSensors[0].classification] && selectedSensors[0].classification != 'custom') {
					setSensorClass({ value: selectedSensors[0].classification, label: SENSOR_TYPES[selectedSensors[0].classification].label });

					if (ifAllSame(selectedSensors, 'subcategory')) {
						setSensorSpecificType({ 
							value: selectedSensors[0].subcategory, 
							label: SENSOR_TYPES[selectedSensors[0].classification].subcategories[selectedSensors[0].subcategory].label 
						});
					}
					
					const all_unit_labels = Object.values(SENSOR_TYPES[selectedSensors[0].classification].subcategories[selectedSensors[0].subcategory].units).map(unit => unit.label);
					
					if (ifAllSame(selectedSensors, 'unit') && all_unit_labels.includes(selectedSensors[0].unit)) {
						setSensorUnit({ value: selectedSensors[0].unit, label: selectedSensors[0].unit });
					}
				}
				else {
					if (selectedSensors[0].classification === 'custom') {
						setSensorClass({ value: selectedSensors[0].classification, label: selectedSensors[0].classification });
						setSensorSpecificType({ value: selectedSensors[0].subcategory, label: selectedSensors[0].subcategory });
						setSensorUnit({ value: selectedSensors[0].unit, label: selectedSensors[0].unit });
					} else {
						setSensorClass({ value: 'custom', label: 'Custom' });
						if (selectedSensors[0].classification === 'other') {
							setSensorSpecificType();
						} else {
							setSensorSpecificType({ value: selectedSensors[0].classification, label: selectedSensors[0].classification });
						}
						setSensorUnit({ value: selectedSensors[0].unit, label: selectedSensors[0].unit });
					}
				}
				if (ifAllSame(selectedSensors, 'multiplier')) {
					setSensorMultiplier(selectedSensors[0].multiplier);
				}
				
				if (ifAllSame(selectedSensors, 'includeintotal')) {
					setSensorIncludedInTotal(selectedSensors[0].includeintotal);
				}
				
				if (ifAllSame(selectedSensors, 'accumulateddata')) {
					setAccumulatedData(selectedSensors[0].accumulateddata);
				}
				
				if (ifAllSame(selectedSensors, 'alarmgraceminutes')) {
					setAlarmgraceminutes(selectedSensors[0].alarmgraceminutes);
				}
				setSensorExpanded(true);
			}
			else {
				setSensorExpanded(false);
			}
	
		}
	}
	function onDeleteButtonClick(sensorType) {
		setShowConfirmDeletePopup(true);
		setSensoridsToDelete((sensorType === SENSOR_TYPE.registered ? selectedSensors : unregSelectedSensors).map(sen => sen.sensorid));
	}
	// Function to export selected sensor data to Excel
	function exportSelectedToExcel() {
		const selectedSensorIds = selectedSensors.map(sensor => sensor.sensorid);
		const selectedSensorData = sensorData.filter(sensor => selectedSensorIds.includes(sensor.sensorid));
		exportToExcel(selectedSensorData);
	}

	// Function to export data to an Excel file
	function exportToExcel(data) {
		const worksheet = XLSX.utils.json_to_sheet(data);

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sensor Data');

		const excelBuffer = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
		});
		const blob = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});

		const filename = 'selected_sensors_data.xlsx';
		saveAs(blob, filename);
	}

	// Function to handle resetting all local variables to their default values
	function resetSensorState() {
		setSensorName();
		setSensorClass();
		setSensorSpecificType();
		setSensorUnit();
		setSensorMultiplier();
		setSensorIncludedInTotal();
		setAccumulatedData();
		setAlarmgraceminutes();
		setEditPopupSelectedPropertyId();
		setSensorGroups();
	}

	// when changing the sensor class, reset the specific type and unit
	function handleSensorClassChange(newClass) {
		setSensorClass(newClass);
		if (!sensorExpanded) {
			setSensorExpanded(true);
		}
		if (newClass.value === 'custom') return;

		setSensorUnit();
		setSensorSpecificType();
		const newClassSubcategories = SENSOR_TYPES[newClass.value].subcategories;
		if (Object.values(newClassSubcategories).length === 1) {
			const newSpecificType = Object.values(newClassSubcategories)[0];

			setSensorSpecificType({ value: newSpecificType.id, label: newSpecificType.label });
			const newSpecificTypeUnits = SENSOR_TYPES[newClass.value].subcategories[newSpecificType.id].units;
			if (Object.values(newSpecificTypeUnits).length === 1) {
				const newUnit = {value: Object.values(newSpecificTypeUnits)[0].label, label: Object.values(newSpecificTypeUnits)[0].label};
				setSensorUnit(newUnit);
			}
		}
	}

	// when changing the sensor specific type, reset the unit
	function handleSensorSpecificTypeChange(newSpecificType) {
		setSensorSpecificType(newSpecificType);
		if (sensorClass.value === 'custom') return;

		setSensorUnit();
		const newSpecificTypeUnits = SENSOR_TYPES[sensorClass.value].subcategories[newSpecificType.value].units;
		if (Object.values(newSpecificTypeUnits).length === 1) {
			const newUnit = {value: Object.values(newSpecificTypeUnits)[0].label, label: Object.values(newSpecificTypeUnits)[0].label};
			setSensorUnit(newUnit);
		}
	}

	// loading sensors
	useEffect(() => {
		if (props.sensors.length > 0) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 2000); // stop loading after 2 seconds if there is 1 or no sensor

			return () => clearTimeout(timer);
		}
	}, [props.sensors]);

	const locationEditOpts = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const groupEditOpts = props.sensorGroups
		.map(grp => ({ value: grp.sensorgroupid, label: grp.name, locationid: grp.locationid }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const propertyOpts = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const unregSenOpts = [...new Set(unregSensors.map(sen => sen.sensorref.split(' | ')[0]))]
		.map(opt => ({ value: opt, label: opt }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));

	let filteredSens = (
		selectedProperties.length
			? props.sensors.filter(sen => selectedProperties.some(pro => pro.value === sen.locationid))
			: props.sensors
	).sort((a, b) => (a.locationid < b.locationid || (a.locationid === b.locationid && a.name < b.name) ? -1 : 1));
	const filteredUnregSens = (
		unregSelectedProperties.length
			? unregSensors.filter(sen => unregSelectedProperties.some(pro => sen.sensorref.startsWith(pro.value)))
			: unregSensors
	).sort((a, b) => (a.sensorref < b.sensorref ? -1 : 1));

	const apIconProps = {
		style: { color: '#124', width: '1.1rem', height: '1.1rem', marginRight: '0.3rem' },
	};

	const DEFAULTS = Object.seal({ text: { placeholder: '...', cancel: t('generic.cancel'), save: t('generic.save') } }); // BUG: Object.freeze will stop translations from updating.
	return (
		<div style={{ margin: '1rem 0 0 1rem' }}>
			<div style={{ display: 'flex', margin: '0 1rem 1rem 0' }}>
				<SelectionTable
					localization={{
						title: t('sensorAdmin.registeredSensors'),
						nRowsSelected: t('sensorAdmin.numberOfSelectedSensors') + '{0}',
					}} // TODO: Find a better way to do this
					key={'registered' + String(refresh)}
					data={filteredSens}
					dataId='sensorid'
					onSelectionChange={sensors => {
						setSelectedSensors(sensors)
					}}
					//onRowClick={onSensorClick}
					columns={[
						{
							title: t('generic.name'),
							field: 'name',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.name}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setSensors({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												sensorids: [rowData.sensorid],
												name: e.target.value,
											},
										});
									}}
								/>
							),
						},

						{ title: t('generic.city'), field: 'city' },

						{ title: t('generic.address'), field: 'street' },

						/* 						{
													title: t('sensorAdmin.latestUpdated'),
													field: 'timestamp',
													customSort: (a, b) => {
														return new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1;
													},
												}, */
						//{ title: t('generic.id'), field: 'sensorref', maxLength: 200 },
						{
							title: t('generic.value'),
							field: 'value',
							render: rowData => (
								<span
									style={{ cursor: 'default', width: '4rem', display: 'inline-block' }}
								>
									{(rowData.value * (rowData.multiplier || 1) > 1000 ? 
										(rowData.value * (rowData.multiplier || 1)).toExponential(2) : 
										Math.round(rowData.value * (rowData.multiplier || 1) * 100) / 100
									)}
								</span>
							),
						},

						{
							title: t('generic.unit'),
							field: 'unit',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.unit}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setSensors({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												sensorids: [rowData.sensorid],
												unit: e.target.value,
											},
										});
									}}
								/>
							),
						},
					]}
					tableProps={{ maxColumnLength: 28,}}
				/>
				<SelectionSidebar
					buttons={[
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								onEditButtonClick(SENSOR_TYPE.registered);
								setEditPopupType(POPUP_TYPES.edit);
							},
							disabled: !selectedSensors.length,
						},

						{
							label: t('genericAdmin.deleteSelected'),
							onClick: () => {
								onDeleteButtonClick(SENSOR_TYPE.registered);

							},
							disabled: !selectedSensors.length,
						},
						{
							label: t('genericAdmin.exportExcel'),
							onClick: () => exportSelectedToExcel(sensorData), // onDeleteButtonClick(SENSOR_TYPE.registered),
							//enabled: !selectedSensors.length,
							disabled: !selectedSensors.length,
						},
					]}
					filters={propertyOpts}
					updateSelectedFilters={properties => setSelectedProperties(properties || [])}
					localization={{ filterTitle: t('sensorAdmin.filterSensors'), filterPlaceholder: t('sensorAdmin.selectProperties') }}
					style={SIDEBAR_STYLE}
				/>
			</div>

			{(props.userInfo || {}).propertyAccess === PROP_SELECTION_TYPES.all.id && (
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
					<SelectionTable
						localization={{
							title: t('sensorAdmin.nonRegisteredSensors'),
							nRowsSelected: t('sensorAdmin.numberOfSelectedSensors') + '{0}', // TODO: Find a better way to do this
						}}
						data={filteredUnregSens}
						dataId='sensorid'
						onSelectionChange={sensors => setUnregSelectedSensors(sensors)}
						onRowClick={onSensorClick}
						columns={[
							{ title: t('generic.name'), field: 'name' },
							{ title: t('generic.city'), field: 'city' },
							{ title: t('generic.address'), field: 'street' },

							{
								title: t('sensorAdmin.latestUpdated'),
								field: 'timestamp',
								customSort: (a, b) => {
									return new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1;
								},
							},
							{ title: t('generic.id'), field: 'sensorref' },
						]}
						tableProps={{ maxColumnLength: 70 }}
					/>
					<SelectionSidebar
						buttons={[
							{
								label: t('genericAdmin.editSelected'),
								onClick: () => onEditButtonClick(SENSOR_TYPE.unregistered),
								disabled: !unregSelectedSensors.length,
							},

							{
								label: t('genericAdmin.deleteSelected'),
								onClick: () => onDeleteButtonClick(SENSOR_TYPE.unregistered),
								disabled: !unregSelectedSensors.length,
							},
						]}
						filters={unregSenOpts}
						updateSelectedFilters={properties => setUnregSelectedProperties(properties || [])}
						localization={{ filterTitle: t('sensorAdmin.filterSensors'), filterPlaceholder: t('sensorAdmin.selectKeyword') }}
						style={SIDEBAR_STYLE}
					/>
				</div>
			)}

			<Dialog
				open={editPopupEnabled}
				classes={makeStyles({ paper: { minWidth: '32rem', maxWidth: '52rem', overflow: 'visible' } })()}
				onClose={() => {
					setEditPopupEnabled(false);
				}}
			>
				<DialogTitle>
					{
						sensorsUnderEdit.length === 1 ?
							t('sensorAdmin.editSensors') + (sensorsUnderEdit[0].locationid ? sensorsUnderEdit[0].name : sensorsUnderEdit[0].sensorref)
							: t('sensorAdmin.edit') + sensorsUnderEdit.length + t('sensorAdmin.selectedSensors')
					}
				</DialogTitle>

				<DialogContent style={{
					padding: '0 1.5rem',
					overflowY: 'auto',
					overflow: 'visible',
				}}>
					<DialogContentText component='div' style={{ marginTop: '-0.1rem' }}>
						{
						sensorsUnderEdit.length === 1
							? t('sensorAdmin.selectValuesNewSensorPromt_one')
							: t('sensorAdmin.selectValuesNewSensorPromt_any')
						}
					</DialogContentText>
					<div style={{ display: 'flex', gap: '1rem' }}>
						<div style={{ flex: 1 }}>

							<div key={'name'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('generic.name')}</div>
								<TextField
									type={'text'}
									margin='dense'
									variant='outlined'
									fullWidth
									multiline={false}
									rows={2}
									rowsMax={15}
									inputProps={{ style: { fontSize: '86%' } }}
									style={{ margin: '0' }}
									//show all sensorsUnderEdit names as placeholder if multiple sensors are selected
									placeholder={sensorsUnderEdit.length === 1 ? sensorsUnderEdit[0].name : sensorsUnderEdit.map(sen => sen.name).join(', ')}
									onChange={e => setSensorName(e.target)}
									disabled={sensorsUnderEdit.length === 1 ? false : true}
								/>
							</div>

							<div key={'locationid'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('generic.property')}</div>

								<Select
									id={"sensorlocationid"}
									label={"Sensor Locationid"}
									options={locationEditOpts.concat({ value: null, label: t('sensorAdmin.noProperty') })}
									value={editPopupSelectedPropertyId}
									onChange={value => {
										setEditPopupSelectedPropertyId(value);
									}}
									components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
									placeholder={
										sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'locationid')
											? (
												locationEditOpts.find(
													pro =>
														pro.value === sensorsUnderEdit[0].locationid
												) || {
													label: t('sensorAdmin.noProperty'),
												}
											).label
											: t('sensorAdmin.noProperty')
									}
									styles={
										(styles, state) => ({
											...styles,
											boxShadow: state.isFocused ? '0 0 0 1px ' + colors.primary : null,
											borderRadius: '4px',
											borderColor: state.isFocused ? colors.primary : '#0000003b',
											'&:hover': state.isFocused ? null : { borderColor: '#000' },
										})
									}
								/>
							</div>

							<div key={'sensorgroupid'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.group')}</div>

								<Select
									id={"sensorgroupid"}
									label={"Sensor Sensorgroupid"}
									options={groupEditOpts
										.filter(grp => grp.locationid === editPopupSelectedPropertyId)
										.concat({ value: null, label: t('sensorAdmin.noGroup') })}
									value={sensorGroups}
									placeholder={
										sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'sensorgroupid')
										&& sensorsUnderEdit[0].sensorgroupid
											? (
												groupEditOpts.find(
													grp =>
														grp.locationid === editPopupSelectedPropertyId &&
														Number(grp.value) === sensorsUnderEdit[0].sensorgroupid
												) || {
													label: t('sensorAdmin.noGroup'),
												}
											).label
											: t('sensorAdmin.noGroup')
									}

									onChange={value => {
										setSensorGroups(value);
									}}
									components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
									styles={
										(styles, state) => ({
											...styles,
											boxShadow: state.isFocused ? '0 0 0 1px ' + colors.primary : null,
											borderRadius: '4px',
											borderColor: state.isFocused ? colors.primary : '#0000003b',
											'&:hover': state.isFocused ? null : { borderColor: '#000' },
										})
									}
								/>
							</div>

							<div key={'alarmperiod'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.alarmPeriods')}</div>
								<div style={{ display: 'flex' }}>
									{(() => {
										const allActivated = sensorsUnderEdit.every(sen =>
											props.alarmPeriodSensors.map(per => per.sensorid).includes(sen.sensorid)
										);
										return (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													padding: '0.3rem 0.5rem',
													marginRight: '0.5rem',
													background: apCb ? '#afb' : allActivated ? '#eef' : '#eee',
													borderRadius: '5px',
												}}
											>
												{apCb ? <EditIcon {...apIconProps} /> : allActivated && <CheckIcon {...apIconProps} />}
												{apCb
													? t('sensorAdmin.unsavedChanges')
													: allActivated
														? t('sensorAdmin.activated')
														: sensorsUnderEdit.some(sen =>
															props.alarmPeriodSensors.map(per => per.sensorid).includes(sen.sensorid)
														)
															? t('sensorAdmin.activatedCertainSensors')
															: t('sensorAdmin.noneSpecified')}
											</div>
										);
									})()}
									<Button color='primary' size='small' variant='outlined' onClick={() => setShowAlarmPeriodPopup(true)}>
										{t('generic.edit')}
									</Button>
								</div>
							</div>
							
							{/* add a gap to make the alarmperiod be the same height as the other fields */}
							<div style={{ height: '0.3rem' }}></div>

							<div key={'alarmgraceminutes'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.alarmDelayMinutes')}</div>
								<TextField
									type={'text'}
									margin='dense'
									variant='outlined'
									fullWidth
									multiline={false}
									rows={2}
									rowsMax={15}
									inputProps={{ style: { fontSize: '86%' } }}
									style={{ margin: '0' }}
									//show all sensorsUnderEdit names as placeholder if multiple sensors are selected
									onChange={e => setAlarmgraceminutes(e.target.value)}
									placeholder={
										sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'alarmgraceminutes')
											&& sensorsUnderEdit[0].alarmgraceminutes
											? String(sensorsUnderEdit[0].alarmgraceminutes)
											: '...'
									}
								/>
							</div>
						</div>

						<div style={{ flex: 1 }}>
							<div key={'sensortype'} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.sensortype')}</div>

								<Select
									id={"sensortypeselect"}
									label={t('sensorAdmin.sensortype')}
									options={Object.values(SENSOR_TYPES).map(classi => {
										return { value: classi.id, label: classi.label };
									})}
									onChange={handleSensorClassChange}
									value={sensorClass ? sensorClass : '...'}
									placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'classification')
										 && SENSOR_TYPES[sensorsUnderEdit[0].classification] ? SENSOR_TYPES[sensorsUnderEdit[0].classification].label : '...'}
									components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
									styles={
										(styles, state) => ({
											...styles,
											boxShadow: state.isFocused ? '0 0 0 1px ' + colors.primary : null,
											borderRadius: '4px',
											borderColor: state.isFocused ? colors.primary : '#0000003b',
											'&:hover': state.isFocused ? null : { borderColor: '#000' },
										})
									}
								/>
							</div>
							{sensorExpanded && sensorClass && (
    							<>
								{/* sensor subtype multible*/}
								{sensorClass.value != 'custom' &&
									Object.values(SENSOR_TYPES[sensorClass.value].subcategories)[0].label && Object.values(SENSOR_TYPES[sensorClass.value].subcategories).length > 1 && (
										<div key={'sensorsubtype'} style={{ margin: '0 0 0.5rem 0' }}>
											<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.sensorsubtype')}</div>

											<Select
												id={"subcategory"}
												label={t('sensorAdmin.sensorsubtype')}
												options={sensorClass ? Object.values(SENSOR_TYPES[sensorClass.value].subcategories).map(sub => {
													return { value: sub.id, label: sub.label };
												}) : []}
												onChange={handleSensorSpecificTypeChange}
												value={sensorClass && sensorSpecificType ? sensorSpecificType : '...'}
												placeholder={ifAllSame(sensorsUnderEdit, 'subcategory') ? sensorsUnderEdit[0].subcategory : '...'}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
											/>
										</div>
									)}
								{/* sensor subtype text */}
								{sensorClass.value === 'custom' && (
									<div key={'sensorsubtype'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('sensorAdmin.sensorsubtype')}</div>

										<TextField
											type={'text'}
											margin='dense'
											variant='outlined'
											fullWidth
											multiline={false}
											rows={2}
											rowsMax={15}
											inputProps={{ style: { fontSize: '86%' } }}
											style={{ margin: '0' }}
											placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'subcategory')
												 && sensorsUnderEdit[0].subcategory ? sensorsUnderEdit[0].subcategory : '...'}
											onChange={handleSensorSpecificTypeChange}
											value={sensorSpecificType ? sensorSpecificType.label : ''}
										/>
									</div>
								)}
								
								{/* sensor unit multible */}
								{sensorClass.value !== 'custom' && (
									<div key={'sensorunit'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('generic.unit')}</div>
					
										<Select
											id={"unit"}
											label={t('generic.unit')}
											options={sensorSpecificType ? Object.values(SENSOR_TYPES[sensorClass.value].subcategories[sensorSpecificType.value].units).map(unit => {
												return { value: unit.label, label: unit.label };
											}) : []}
											onChange={(unit) => {
												setSensorUnit(unit);
											}}
											value={sensorUnit ? sensorUnit : '...'}
											placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'unit')
												&& sensorsUnderEdit[0].unit ? sensorsUnderEdit[0].unit : '...'}
											components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
										/>
									</div>
								)}
								{/* sensor unit text */}
								{sensorClass.value === 'custom' && (
									<div key={'sensorunit'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('generic.unit')}</div>

										<TextField
											type={'text'}
											margin='dense'
											variant='outlined'
											fullWidth
											multiline={false}
											rows={2}
											rowsMax={15}
											inputProps={{ style: { fontSize: '86%' } }}
											style={{ margin: '0' }}
											onChange={e => setSensorUnit({ value: e.target.value, label: e.target.value })}
											placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'unit')
												 && sensorsUnderEdit[0].unit ? sensorsUnderEdit[0].unit : '...'}
											value={sensorUnit ? sensorUnit.label : ''}
										/>
									</div>
								)}
								{ SENSOR_TYPES[sensorClass.value].askForMultiplier && (
									<div key={'multiplier'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>{t('generic.multiplier')}</div>

										{textFieldWrapper(
											e => setSensorMultiplier(e.target.value),
											sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'multiplier')
											&& sensorsUnderEdit[0].multiplier ? String(sensorsUnderEdit[0].multiplier) : '...'
										)}
									</div>
								)}
								{SENSOR_TYPES[sensorClass.value].askForTotalInclusion && (
									<div key={'includedInTotal'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>
											{t('sensorAdmin.includedInTotal')}
										</div>
										<Select
											id={"includedInTotal"}
											label={"Included In Total"}
											options={[
												{ value: true, label: t('generic.yes') },
												{ value: false, label: t('generic.no') },
											]}
											onChange={value => {
												setSensorIncludedInTotal(value)
											}}
											value={sensorIncludedInTotal}
											placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'includeintotal')
												&& sensorsUnderEdit[0].includeintotal != null ? sensorsUnderEdit[0].includeintotal ? t('generic.yes') : t('generic.no') : '...'}
											components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
										/>
									</div>
								)}

								{sensorClass && SENSOR_TYPES[sensorClass.value].subcategories && SENSOR_TYPES[sensorClass.value].askForAccumulatedData && (
									<div key={'accumulatedData'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>
											{t('sensorAdmin.accumulatedData')}

											<Select
												id={"accumulatedData"}
												label={"Live Data"}
												options={[
													{ value: true, label: t('generic.yes') },
													{ value: false, label: t('generic.no') },
												]}
												onChange={value => setAccumulatedData(value)}
												value={accumulatedData}
												placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'accumulateddata')
													&& sensorsUnderEdit[0].accumulateddata != null ? sensorsUnderEdit[0].accumulateddata ? t('generic.yes') : t('generic.no') : '...'}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
											/>

										</div>
									</div>
								)}
								{sensorClass && SENSOR_TYPES[sensorClass.value].subcategories && SENSOR_TYPES[sensorClass.value].askForSetPoint && (
									<div key={'setPoint'} style={{ margin: '0 0 0.5rem 0' }}>
										<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem', padding: '0.0 0.0 0.0 0.0' }}>
											{t('sensorAdmin.setPoint')}
											<Select
												id={"setPoint"}
												label={"Set Point"}
												options={[
													{ value: true, label: t('generic.yes') },
													{ value: false, label: t('generic.no') },
												]}
												onChange={value => setSetPoint(value)}
												value={setPoint}
												placeholder={sensorsUnderEdit.length && ifAllSame(sensorsUnderEdit, 'setpoint')
													&& sensorsUnderEdit[0].setpoint ? sensorsUnderEdit[0].setpoint ? t('generic.yes') : t('generic.no') : '...'}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
											/>
										</div>
									</div>
								)}
								</>
							)}
						</div>
					</div>

				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => {
							setEditPopupEnabled(false);
							resetSensorState();
						}}
						style={{ color: colors.text }}
					>
						{DEFAULTS.text.cancel}
					</Button>
					<Button
						disabled={!(sensorsUnderEdit[0]?.classification || sensorClass) ||
							!(sensorSpecificType || sensorsUnderEdit[0]?.subcategory) ||
							!(sensorUnit || sensorsUnderEdit[0]?.unit)
						}

						onClick={() => {
							if (apCb) {
								apCb()
							};
							setApCb();
							if (sensorGroups && sensorGroups.value && !props.sensorGroups.some(grp => grp.sensorgroupid === sensorGroups.value)) {
								const vars = { name: sensorGroups.value, locationid: editPopupSelectedPropertyId || sensorsUnderEdit[0]?.locationid };
								addSensorGroup({
									variables: vars,
								});
								sensorGroupQueue.push({ ...vars, sensorIds: sensorsUnderEdit.map(sen => sen.sensorid) });
							}
							if (editPopupType === POPUP_TYPES.edit &&
								(sensorName || sensorClass || sensorSpecificType || sensorUnit || sensorMultiplier
									|| sensorIncludedInTotal || accumulatedData || sensorGroups || alarmgraceminutes ||
									editPopupSelectedPropertyId)) {
								const variables = {
									sensorids: sensorsUnderEdit.map(sen => sen.sensorid && Number(sen.sensorid)),
									name: sensorName?.value,
									locationid: editPopupSelectedPropertyId?.value,
									sensorgroupid: sensorGroups ? sensorGroups.value ? Number(sensorGroups.value) : sensorGroups.value : undefined,
									classification: sensorClass?.value,
									subcategory: sensorSpecificType?.value,
									unit: sensorUnit?.value,
									multiplier: sensorMultiplier ? Number(sensorMultiplier) : undefined,
									includeintotal: sensorIncludedInTotal?.value,
									accumulateddata: accumulatedData?.value,
									alarmgraceminutes: alarmgraceminutes ? Number(alarmgraceminutes) : undefined,
									setpoint: setPoint?.value,
								};

								setSensors({
									variables: variables,
								});

							}

							setEditPopupEnabled(false);
							if (editPopupType === POPUP_TYPES.edit) {
								Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorInfo'), 'success');
							} else if (editPopupType === POPUP_TYPES.delete) {
								Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorDeleted'), 'success');
							}
							resetSensorState();
							setRefresh(refresh + 1);
						}}
					>
						{DEFAULTS.text.save}
					</Button>
				</DialogActions>
			</Dialog>

			<DialogWrapper
				title={t('sensorAdmin.editAlarmPeriods')}
				width='30rem'
				dialogProps={{ open: showAlarmPeriodPopup, onClose: () => setShowAlarmPeriodPopup(false) }}
			>
				<AlarmPeriodEditor
					sensors={sensorsUnderEdit}
					onFinish={cb => {
						setShowAlarmPeriodPopup(false);
						setApCb(() => cb);
					}}
				/>
			</DialogWrapper>

			<ConfirmPopup
				enabled={showConfirmDeletePopup}
				title={t('sensorAdmin.confirmDeletion')}
				text={t('sensorAdmin.confirmDeletePromt_any', { count: sensoridsToDelete.length })}
				onFinish={suc => {
					const date = new Date();
					const timestamp = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
					if (suc) {
						const deletePromises = sensoridsToDelete.map(sensorid => {
							const sensorref = props.sensors.find(sen => sen.sensorid === sensorid)?.sensorref + '_removed_' + props.userInfo.username + timestamp;
							return setSensors({
								variables: {
									sensorids: [sensorid],
									sensorref: sensorref,
									sensorgroupid: null,
									locationid: null,
								},
							});
						});

						Promise.all(deletePromises).then(() => {
							Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorDeleted'), 'success');
							setShowConfirmDeletePopup(false);
						});
					} else {
						setShowConfirmDeletePopup(false);
					}
				}}
			/>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingSensors')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</div>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.sensors, STORE.sensorGroups, STORE.properties, STORE.alarmPeriodSensors), {
	setSensors,
	setSensorGroups,
})(SensorAdministration);

import { gql } from '@apollo/client';

export const MUTATION_SET_KC_RECIPIENTS = gql`
	mutation ($id: String!, $add: Boolean!, $reportRecipients: [String!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, reportRecipients: $reportRecipients) {
			reportRecipients
		}
	}
`;
export const MUTATION_SET_KC_OPTIONS = gql`
	mutation ($ids: [String!]!, $reportOptions: [String!]!) {
		setKeycloakUsers(ids: $ids, reportOptions: $reportOptions) {
			reportOptions
		}
	}
`;
export const MUTATION_SET_KC_SENSORS = gql`
	mutation ($ids: [String!]!, $reportSensors: [Int!]!) {
		setKeycloakUsers(ids: $ids, reportSensors: $reportSensors) {
			reportSensors
		}
	}
`;
export const MUTATION_SET_KC_PROPERTIES = gql`
	mutation ($ids: [String!]!, $reportProperties: [Int!]!) {
		setKeycloakUsers(ids: $ids, reportProperties: $reportProperties) {
			reportProperties
		}
	}
`;

export const MUTATION_ADD_AICONTROLS = gql`
	mutation ($aiControls: AiControlsFilter!) {
		addAiControls(filter: $aiControls)
	}
`;

export const QUERY_REPORTS = gql`
	query Reports {
		getReports {
			reportid
			reportname
			reporttype
			shoulddeliver
			recipients {
				email
				intervals
			}
			lastedit
			properties
			pages
			sensors
		}
	}
`;

export const MUTATION_DELETE_REPORT = gql`
	mutation ($reportid: Int!) {
		deleteReport(reportid: $reportid)
	}
`;

export const MUTATION_SET_REPORT = gql`
	mutation ($reportFilter: ReportFilter!) {
		setReport(reportFilter: $reportFilter) {
			reportid
			reportname
			reporttype
			shoulddeliver
			recipients {
				email
				intervals
			}
			properties
			lastedit
			pages
			sensors
		}
	}
`;

export const QUERY_PROPERTIES = gql`
	query {
		getSensorLocations {
			locationid
			city
			street
		}
	}
`;

export const QUERY_SENSORDATA_NEW = gql`
	query ($sensorDataFilter: SensorDataNewFilter!) {
		getSensorDataNew(filter: $sensorDataFilter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

export const QUERY_SENSORDATA_VIEW_SHORT = gql`
	query ($sensorViewFilter: SensorViewFilter) {
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			name
			city
			street
			groupname
			locationid
		}
	}
`;

export const QUERY_SENSORDATA_VIEW = gql`
	query ($sensorViewFilter: SensorViewFilter) {
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			sensorref
			name
			unit
			value
			classification
			locationid
			city
			street
			cadastral
			area
			timestamp
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			sensorgroupid
			groupname
			multiplier
		}
	}
`;

export const QUERY_SENSORDATA_NEW_AND_VIEW = gql`
	query ($sensorDataFilter: SensorDataNewFilter!, $sensorViewFilter: SensorViewFilter) {
		getSensorDataNew(filter: $sensorDataFilter) {
			sensorid
			sensordata {
				x
				y
			}
		}
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			sensorref
			name
			unit
			value
			classification
			locationid
			city
			street
			cadastral
			area
			timestamp
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			sensorgroupid
			groupname
			multiplier
		}
	}
`;

export const QUERY_GET_AI_LOGG_VIEW = gql`
	query ($filter: AiloggViewFilter) {
		getAiloggView(filter: $filter) {
			timestamp
			total_curvechange
		}
	}
`;

export const GET_AI_CONTROL = gql`
	query GetAiControls($filter: AiControlFilter) {
		getAiControls(filter: $filter) {
			index
			locationid
			system
			ducid
			name
			sensorid
			communicationtype
			sensortype
			value
			minimum
			maximum
		}
	}
`;

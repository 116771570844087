import GaugeComponent from 'react-gauge-component';
import React from 'react';

const AUTOMATIC_MINMAX_OFFSET = 7;
// #5BE12C'

function setAutomaticArc(minT, maxT, minV, maxV) {
	function calculateGradingOffset(threshold, value) {
		const extremeValue = value === undefined ? AUTOMATIC_MINMAX_OFFSET : value;
		return Math.floor(Math.abs(threshold - extremeValue) / 2);
	}
	return [
		{
			limit: minV,
			color: '#4D93DD',
		},
		{
			limit: minV + calculateGradingOffset(minT, minV),
			color: '#4BDCE3',
		},
		{
			limit: minT,
			color: '#5BE12C',
		},
		{
			limit: maxT,
			color: '#5BE12C',
		},
		{
			limit: maxV - calculateGradingOffset(maxT, maxV),
			color: '#FFD512',
		},
		{
			limit: maxV,
			color: '#F53B3B',
		},
	];
}

export function Guage({ value, unit, maxValue, minValue, automaticArcColor, thresholds, ...props }) {
	function labelFormat(value) {
		return `${value} ${unit}`;
	}

	const automaticArc =
		(minValue === undefined || maxValue === undefined) && (thresholds?.[0] !== undefined || thresholds?.[1] !== undefined);

	// Make sure that the thresholds are not the same
	const minT = thresholds?.[0] || 0;
	const maxT = thresholds?.[1] <= minT ? minT + 1 : thresholds?.[1] || minT + 1;

	function calculateMinMaxValue() {
		// If the value is lower than expected, take a dynamic min/max value to show the actual value
		// and make it uniform for both sides of the thresholds
		const largestOffset = Math.max(
			Math.min(Math.abs(Math.floor(value || 0) - minT), Math.abs(Math.floor(value || 0) - maxT)),
			AUTOMATIC_MINMAX_OFFSET
		);
		return [minT - largestOffset, maxT + largestOffset];
	}

	const [autoMinV, autoMaxV] = calculateMinMaxValue();
	const [minV, maxV] = [minValue || autoMinV, maxValue || autoMaxV];

	const guageValue = Math.max(Math.min(value, maxV), minV);
	return (
		<GaugeComponent
			value={guageValue}
			minValue={minV}
			maxValue={maxV}
			type='radial'
			labels={{
				tickLabels: {
					type: 'outer',
					ticks: props.ticks ? props.ticks.map(value => ({ value })) : [],
					defaultTickValueConfig: {
						formatTextValue: value => labelFormat(value),
					},
					...props.tickLabels,
				},
				valueLabel: {
					style: {
						fontSize: '40rem',
						fontWeight: 'bold',
						fill: '#000',
						textShadow: '', // Otherwise automatic shadow
					},
					matchColorWithArc: true,
					formatTextValue: value => labelFormat(value, unit),
					...props.valueLabel,
				},
			}}
			arc={{
				subArcs: automaticArc ? setAutomaticArc(minT, maxT, minV, maxV) : [],
				padding: 0.02,
				width: 0.2,
				gradient: true,
				...props.arc,
			}}
			pointer={{
				elastic: true,
				animationDelay: 0,
			}}
			{...props.guageProps}
		/>
	);
}

export default Guage;

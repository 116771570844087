import { GRAPH_TYPES } from '../constants';

const UNIT_GRAPH_TYPES = Object.freeze({
	default: GRAPH_TYPES.line,
	C: GRAPH_TYPES.line,
	kWh: GRAPH_TYPES.bar,
	MWh: GRAPH_TYPES.bar,
	Wh: GRAPH_TYPES.bar,
	m3: GRAPH_TYPES.bar,
	'm³': GRAPH_TYPES.bar,
	kg: GRAPH_TYPES.bar,
	m2: GRAPH_TYPES.bar,
	'm²': GRAPH_TYPES.bar,
});

function getGraphTypeForUnit(unit) {
	let graphType = UNIT_GRAPH_TYPES['default'];

	if (unit)
		Object.keys(UNIT_GRAPH_TYPES).forEach(key => {
			if (key.toLowerCase() === unit.toLowerCase()) graphType = UNIT_GRAPH_TYPES[key];
		});

	return graphType;
}

export default getGraphTypeForUnit;
